import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Grid } from '@mui/material';
import { validateForm } from '../../../../_helpers';
import { ProfileAction } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { postAuthRoutes, preAuthRoutes } from "../../../../routes";
import { SubmitButton, FormPassword, CardContent, CardHeader, SweetAlert, Animate } from '../../../../_components';
import { StyledChangePassword, validateChangePassword } from '.';

/* Initializing the input with the default values. */
const inputs = { current_password: '', new_password: '', confirm_password: '' };

function ChangePassword() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /* Destructuring the state from redux store. */
    const changePassword = (params) => dispatch(ProfileAction.changePassword(params));
    const { change_password_loading, change_password_errors } = useSelector((state) => state.ProfileReducer);


    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    useEffect(() => {
        setErrors({ ...change_password_errors });
    }, [change_password_errors]);

    /**
     * It takes the name and value of the input, validates it, and sets the errors and data state
     * @param {Object} e form object
     * @param {String} e.name input name
     * @param {String} e.value input value
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleChange(e) {
        const { name, value } = e;
        /* Validating the input and setting the errors state. */
        setErrors({ ...errors, ...(validateChangePassword(name, value, data)) });
        data[e.target.name] = e.target.value;
        setData({ ...data });
    }

    /**
     * The function handles the submit event of the form. It prevents the default action of the form,
     * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
     * dispatches the corresponding action
     * @param {Object} e the event object
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        /* Iterating through the data object and validating each key value pair. */
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateChangePassword(name, value, data);
            formErrors[name] = inputError[name];
        }

        /* Checking if the form is valid or not. If the form is not valid, it sets the errors state and returns. */
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchUpdatePassword(data);
    }

    /**
     * The function dispatches an action to change the user's password and handles the response accordingly.
     * @param {Object} data
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    const dispatchUpdatePassword = (data) => {
        let dispatchAction = changePassword(data);
        dispatchAction.then((response) => {
            if (response.status === 1) {
                let timerInterval;
                SweetAlert.fire({
                    title: 'Password Updated!',
                    html: "Your account password has been updated.You will be redirected to the login screen soon!<br/><br/>I will close in <b></b> milliseconds.Please login again.",
                    icon: 'success',
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                        SweetAlert.showLoading();
                        const b = SweetAlert.getHtmlContainer().querySelector('b');
                        timerInterval = setInterval(() => { b.textContent = SweetAlert.getTimerLeft(); }, 200);
                    },
                    willClose: () => {
                        setTimeout(() => {
                            let redirectPath = preAuthRoutes('sign_in').path;
                            navigate(redirectPath);
                        }, 2000);
                        clearInterval(timerInterval);
                    }
                });
            } else {
                setErrors({ ...response.errors });
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }

        });
    };

    return (
        <StyledChangePassword >
            <CardHeader title={postAuthRoutes('change_password').name} />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={6} display={`flex`} alignItems={`center`}>
                        <Animate animate='right-left'>
                            <form onSubmit={handleSubmit} noValidate >
                                <Grid container spacing={2} >
                                    <Grid item xs={12} >
                                        <FormPassword
                                            tabIndex={1}
                                            label='Current password'
                                            name='current_password'
                                            value={data.current_password}
                                            error={action.isSubmitted && errors.current_password ? errors.current_password : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormPassword
                                            tabIndex={1}
                                            label='New password'
                                            name='new_password'
                                            value={data.new_password}
                                            error={action.isSubmitted && errors.new_password ? errors.new_password : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormPassword
                                            tabIndex={1}
                                            label='Confirm password'
                                            name='confirm_password'
                                            value={data.confirm_password}
                                            error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <SubmitButton label={'Change Password'} fullWidth loading={change_password_loading} />
                                    </Grid>
                                </Grid>
                            </form>
                        </Animate>
                    </Grid>
                    <Grid item xs={6} className='right-grid'>
                        <Animate>
                            <svg xmlns="http://www.w3.org/2000/svg" width="300" data-name="Layer 1" viewBox="0 0 793 551.732"   >
                                <ellipse cx="158" cy="539.732" fill="#e6e6e6" rx="158" ry="12"></ellipse>
                                <path fill="#2f2e41" d="M120.772 122.42c27.497-11.696 61.745-4.286 95.191.857.311-6.228 4.084-13.808.132-18.153-4.801-5.279-4.359-10.825-1.47-16.403 7.388-14.265-3.197-29.444-13.884-42.065a23.67 23.67 0 00-19.756-8.292l-19.797 1.414a23.71 23.71 0 00-21.053 16.946c-4.727 6.43-7.257 12.84-5.664 19.219-7.081 4.839-8.27 10.68-5.089 17.264 2.698 4.146 2.67 8.182-.123 12.106a55.89 55.89 0 00-8.31 16.506zM774.209 476.963H213.79A18.791 18.791 0 01195 458.172q304.727-35.415 598 0a18.791 18.791 0 01-18.791 18.791z" ></path>
                                <path fill="#3f3d56" d="M793 459.277l-598-1.105 69.306-116.616.332-.552V83.996a23.752 23.752 0 0123.754-23.754h407.9a23.752 23.752 0 0123.754 23.754v258.776z" ></path>
                                <path fill="#fff" d="M287.85 76.823a7.746 7.746 0 00-7.737 7.737v234.336a7.747 7.747 0 007.737 7.738h412.3a7.747 7.747 0 007.737-7.738V84.56a7.747 7.747 0 00-7.737-7.737z" ></path>
                                <path fill="#2f2e41" d="M289.578 357.584a3.325 3.325 0 00-3.013 1.93L265.21 405.94a3.316 3.316 0 003.012 4.702h449.092a3.316 3.316 0 002.965-4.799l-23.212-46.425a3.3 3.3 0 00-2.965-1.833z"  ></path>
                                <circle cx="492.342" cy="67.98" r="4.974" fill="#fff"></circle>
                                <path fill="#2f2e41" d="M448.2 419.484a3.321 3.321 0 00-3.202 2.454l-5.357 19.896a3.316 3.316 0 003.202 4.179h101.874a3.315 3.315 0 003.133-4.401l-6.887-19.896a3.318 3.318 0 00-3.134-2.232z" ></path>
                                <path fill="#2f2e41" d="M720.046 337.135L720.046 341.556 264.306 341.556 264.649 341.004 264.649 337.135 720.046 337.135z"   ></path>
                                <circle cx="707.335" cy="77.375" r="77.375" fill="#4b6e5b"></circle>
                                <path fill="#fff" d="M739.39 111.089h-64.11a4.426 4.426 0 01-4.422-4.422V67.98a4.426 4.426 0 014.421-4.422h64.111a4.426 4.426 0 014.421 4.422v38.687a4.426 4.426 0 01-4.421 4.422zm-64.11-43.11v38.688h64.113l-.003-38.687z"   ></path>
                                <path fill="#fff" d="M727.231 67.98h-39.793V50.294c0-12.81 8.368-22.107 19.897-22.107s19.896 9.297 19.896 22.107zm-35.371-4.422h30.95V50.294c0-10.413-6.364-17.686-15.475-17.686s-15.475 7.273-15.475 17.686z" ></path>
                                <circle cx="707.335" cy="86.218" r="4.421" fill="#fff"></circle>
                                <path fill="#e6e6e6" d="M653.32 247.15H334.68a5.908 5.908 0 01-5.9-5.901v-79.041a5.908 5.908 0 015.9-5.901h318.64a5.908 5.908 0 015.9 5.9v79.042a5.908 5.908 0 01-5.9 5.9zm-318.64-88.483a3.544 3.544 0 00-3.54 3.54v79.042a3.544 3.544 0 003.54 3.54h318.64a3.544 3.544 0 003.54-3.54v-79.041a3.544 3.544 0 00-3.54-3.54z"  ></path>
                                <circle cx="384.19" cy="198.695" r="24.036" fill="#e6e6e6"></circle>
                                <path fill="#e6e6e6" d="M439.703 182.671a4.006 4.006 0 100 8.012h188.858a4.006 4.006 0 000-8.012zM439.703 206.708a4.006 4.006 0 100 8.012h81.266a4.006 4.006 0 100-8.012z"  ></path>
                                <path fill="#ffb8b8" d="M263.522 208.328l-58.903 31.316-.746-26.097c19.226-3.209 37.517-8.797 54.43-17.894l6.16-15.22A10.318 10.318 0 01282 177.755a10.318 10.318 0 01-.909 14.069z"   ></path>
                                <path fill="#2f2e41" d="M119.598 389.133a11.574 11.574 0 011.47-9.363l12.939-19.858a22.612 22.612 0 0129.335-7.74c-5.438 9.257-4.68 17.377 1.878 24.434a117.63 117.63 0 00-27.936 19.045 11.574 11.574 0 01-17.686-6.518z"   ></path>
                                <path fill="#2f2e41" d="M266.205 363.168a22.203 22.203 0 01-18.871 10.78l-85.96.65-3.728-21.622 38.026-11.184-32.062-24.605 35.044-41.008 63.65 59.324a22.203 22.203 0 013.9 27.666z"  ></path>
                                <path fill="#2f2e41" d="M147.953 511.045H127.82c-18.075-123.898-36.474-248.142 17.895-294.515l64.122 10.438-8.202 54.43-35.789 41.008z"  ></path>
                                <path fill="#2f2e41" d="M165.65 539.112a11.574 11.574 0 01-9.364-1.469l-21.859-2.938a22.612 22.612 0 01-7.74-29.335c9.256 5.438 17.377 4.68 24.433-1.88 4.986 10.068 13.2 9.454 21.047 17.936a11.574 11.574 0 01-6.518 17.686z" ></path>
                                <path fill="#ffb8b8" d="M195.672 133.767l-37.28-8.947c6.19-12.674 6.7-26.776 3.727-41.754l25.35-.746c.795 18.626 3.198 36.027 8.203 51.447z"  ></path>
                                <path fill="#4b6e5b" d="M205.918 249.418c-27.14 18.493-46.315.633-60.948-26.923 2.034-16.862-1.259-37.04-7.356-58.967a40.138 40.138 0 0124.505-48.4l32.061 13.42c27.224 22.19 32.582 46.227 22.369 71.579z" ></path>
                                <path fill="#ffb8b8" d="M127.821 152.408l-29.824 15.657 52.938 31.316 7.366 18.17a9.637 9.637 0 01-5.79 12.73 9.637 9.637 0 01-12.76-8.544l-.746-12.663-67.283-22.204a15.733 15.733 0 01-9.873-9.611 15.733 15.733 0 015.903-18.303l54.104-37.118z" ></path>
                                <path fill="#4b6e5b" d="M157.646 155.39c-12.439-5.451-23.75.47-38.026 5.22l-2.237-39.518c14.176-7.556 27.692-9.593 40.263-3.728z"    ></path>
                                <circle cx="172.525" cy="78.093" r="23.802" fill="#ffb8b8"></circle>
                                <path fill="#2f2e41" d="M201 75.09c-23.566 2.307-41.523-1.547-53-12.52v-8.838h51z"  ></path>
                            </svg>
                        </Animate>
                    </Grid>
                </Grid>
            </CardContent>
        </StyledChangePassword>

    );
}


export { ChangePassword };
