import { apiServices } from '../../_helpers';


export const UserManagementServices = {

    /**
     * This is a function in the `UserManagementServices` object that sends a POST request to the
     * `/user-management/users/list` endpoint with the `params` object as the request body. It then
     * logs the `params` object to the console and returns the response from the API call.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getUsersList: (params) => {
        return apiServices.get(`/user-management/users/list`, { params })
            .then((response) => { return response; });
    },

    /**
     * `addUser` is a function in the `UserManagementServices` object that sends a POST request to the
     * `/user-management/users/create` endpoint with the `params` object as the request body. It then
     * returns the response from the API call. This function is likely used to add a new user to the
     * system.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    addUser: (params) => {
        return apiServices.post('/user-management/users/create', params)
            .then((response) => { return response; });
    },

    /**
     * `updateUser` is a function in the `UserManagementServices` object that sends a PUT request to
     * the `/user-management/users/update` endpoint with the `params` object as the request body. It
     * then returns the response from the API call. This function is likely used to update an existing
     * user's information in the system.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    updateUser: (params) => {
        return apiServices.put('/user-management/users/update', params)
            .then((response) => { return response; });
    },

    /**
     * `changeUserStatus` is a function in the `UserManagementServices` object that sends a PUT request
     * to the `/user-management/users/status/update` endpoint with the `params` object as the request
     * body. It then returns the response from the API call. This function is likely used to update the
     * status of an existing user in the system.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    changeUserStatus: (params) => {
        return apiServices.put('/user-management/users/status/update', params)
            .then((response) => { return response; });
    },


    /**
     * `createRole` is a function in the `UserManagementServices` object that sends a POST request to
     * the `/user-management/roles/create` endpoint with the `params` object as the request body. It then
     * returns the response from the API call. This function is likely used to add a new role to the
     * system.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    createRole: (params) => {
        return apiServices.post('user-management/roles/create', params)
            .then((response) => { return response; });
    },

    /**
     *  `updateRole` is a function in the `UserManagementServices` object that sends a POST request to
     * the `/user-management/roles/update` endpoint with the `params` object as the request body. It
     * then returns the response from the API call. This function is likely used to update an existing
     * role's information in the system.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    updateRole: (params) => {
        return apiServices.put('user-management/roles/update', params)
            .then((response) => { return response; });
    },

    /**
     * `changeRoleStatus` is a function in the `UserManagementServices` object that sends a POST request
     * to the `/user-management/roles/status/update` endpoint with the `params` object as the request
     * body. It then returns the response from the API call. This function is likely used to update the
     * status of an existing role in the system.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    changeRoleStatus: (params) => {
        return apiServices.put('user-management/roles/status/update', params)
            .then((response) => { return response; });
    },

    /**
     * `listRoles` is a function in the `UserManagementServices` object that sends a POST request to the
     * `/user-management/roles/list` endpoint with the `params` object as the request body. It then
     * returns the response from the API call. This function is likely used to retrieve a list of roles
     * in the system.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    listRoles: (params) => {
        return apiServices.get('user-management/roles/list', { params })
            .then((response) => { return response; });
    },

    /**
     * `getRoleSelectList` is a function in the `UserManagementServices` object that sends a POST
     * request to the `/user-management/roles/list/select` endpoint with the `params` object as the
     * request body. It then returns the response from the API call. This function is likely used to
     * retrieve a list of roles in the system in a format that can be used for a select dropdown.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getRoleSelectList: (params) => {
        return apiServices.get('user-management/roles/list/select', { params })
            .then((response) => { return response; });
    },

    /**
     * `assignPermission` is a function in the `UserManagementServices` object that sends a POST
     * request to the `/user-management/roles/assign-permission` endpoint with the `params` object as
     * the request body. It then returns the response from the API call. This function is likely used
     * to assign a permission to a role in the system.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    assignPermission: (params) => {
        return apiServices.post('user-management/roles/permissions/assign', params)
            .then((response) => { return response; });
    },

    /**
     * `getAssignedPermissions` is a function in the `UserManagementServices` object that sends a POST
     * request to the `/user-management/roles/get-assigned-permissions` endpoint with the `params`
     * object as the request body. It then returns the response from the API call. This function is
     * likely used to retrieve a list of permissions assigned to a particular role in the system.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getAssignedPermissions: (params) => {
        return apiServices.get('user-management/roles/permissions', { params })
            .then((response) => { return response; });
    },


    /**
     * `addPermission` is a function in the `UserManagementServices` object that sends a POST request
     * to the `/user-management/permissions/create` endpoint with the `params` object as the request body.
     * It then returns the response from the API call. This function is likely used to add a new
     * permission to the system.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    addPermission: (params) => {
        return apiServices.post('/user-management/permissions/create', params)
            .then((response) => { return response; });
    },

    /**
     * `updatePermission` is a function in the `UserManagementServices` object that sends a POST
     * request to the `/user-management/permissions/update` endpoint with the `params` object as the
     * request body. It then returns the response from the API call. This function is likely used to
     * update an existing permission's information in the system.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    updatePermission: (params) => {
        return apiServices.put('/user-management/permissions/update', params)
            .then((response) => { return response; });
    },

    /**
     * `changePermissionStatus` is a function in the `UserManagementServices` object that sends a POST
     * request to the `/user-management/permissions/status/update` endpoint with the `params` object as
     * the request body. It then returns the response from the API call. This function is likely used
     * to update the status of an existing permission in the system.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    changePermissionStatus: (params) => {
        return apiServices.put('/user-management/permissions/status/update', params)
            .then((response) => { return response; });
    },

    /**
     * `getPermissionsList` is a function in the `UserManagementServices` object that sends a POST
     * request to the `/user-management/permissions/list` endpoint with the `params` object as the
     * request body. It then returns the response from the API call. This function is likely used to
     * retrieve a list of permissions in the system.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getPermissionsList: (params) => {
        return apiServices.get('/user-management/permissions/list', { params })
            .then((response) => { return response; });
    },

    /**
     * `permissionSelectList` is a function in the `UserManagementServices` object that sends a POST
     * request to the `/user-management/permissions/list/select` endpoint with the `params` object as
     * the request body. It then returns the response from the API call. This function is likely used
     * to retrieve a list of permissions in the system in a format that can be used for a select
     * dropdown.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    permissionSelectList: (params) => {
        return apiServices.get('/user-management/permissions/list/select', { params })
            .then((response) => { return response; });
    },

};
