import React from 'react'
import { styled, Box, Menu } from '@mui/material';
import backgroundImage from '../../../../_theme/images/account-menu-bg.jpg'

const AccountMenuStyled = styled(({ ...props }) => (<Box {...props} />))`

    & .MuiChip-root {
        display: flex !important;
        align-items: center !important;
        height: 32px;
        padding: 8px !important;
        color: #fff; 
    }
    & .MuiAvatar-root {
        margin-left: auto !important;
        margin-right: -8 !important;
    }

`;

const AccountMenuPaperStyled = styled(({ ...props }) => (<Menu {...props} />))`
    & .MuiMenu-paper {
        min-width: 400px!important;
        margin-top: 8px;
        padding:0;

        & .MuiBox-root {
            & .MuiMenuItem-root {
                display: flex;
                align-items: center;
                padding: 1.1rem 1.5rem;
                border-bottom: 1px solid #f7f8fa;
                transition: background-color 0.3s ease;
                
                & .MuiSvgIcon-root  {
                    font-size: 1.25rem!important;
                }
            }
        }

        & .profile {
            padding:1.5rem;
            color: #fff;
            background-image: url(${backgroundImage});

            & .MuiAvatar-root {
                background-color: #ced4da57;
                border : none !important;
                border-radius: 4px;
            }

            & .MuiTypography-root {
                color : #fff;
            }
        }

    }
`;


export { AccountMenuStyled, AccountMenuPaperStyled }


