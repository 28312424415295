
/**
 * The function handles input change events and returns the name and value of the input element, with
 * special handling for checkboxes, files, and color inputs.
 * @param {Object} e - The parameter `e` is an event object that is passed to the function `handleInputChange`
 * when an input element is changed. It contains information about the event, such as the target
 * element that triggered the event and the type of event that occurred. The function uses this
 * information to determine the name and value
 * @returns {Object} The function `handleInputChange` returns an object with two properties: `name` and `value`.
 * The `name` property is the name of the input element that triggered the event, and the `value`
 * property is the value of the input element.
 * @author Akshay N
 * @created_at 21/05/2023
 */
const getPortals = () => {
    return [
        { value: 'admin', label: 'Admin' },
        { value: 'web_app', label: 'Web App' },
    ];
};

export { getPortals };
