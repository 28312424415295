import { apiServices, cookieServices } from '../../_helpers';

export const AuthServices = {

    /**
     * This is a method in the `AuthServices` object that handles user sign-in functionality. It takes
     * in an object `params` which contains the user's email and password. It then makes a POST request
     * to the `/sign-in` endpoint using the `apiServices` helper function. If the request is
     * successful, it sets a cookie named `accessToken` with the token received in the response and
     * returns the response. The cookie is set to expire in 30 days.
     * @param {Object} params - The params which are used for registration api.
     * @param {String} params.email - Email of the user
     * @param {String} params.password - Password of the user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    signIn: (params) => {

        return apiServices.post('/sign-in', params)
            .then((response) => {
                let date = new Date();
                date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
                const options = { path: '/', expires: date };
                cookieServices.set('accessToken', response.data.token, options);
                cookieServices.set('isDarkMode', response.data.is_dark_mode, options);
                cookieServices.set('layout', response.data.layout, options);
                return response;
            });
    },

    /**
     * `register` is a method in the `AuthServices` object that handles user registration
     * functionality. It takes in an object `params` which contains the user's name, email, password,
     * and confirm password. It then makes a POST request to the `/register` endpoint using the
     * `apiServices` helper function. If the request is successful, it returns the response.
     * @param {Object} params - The params which are used for registration api.
     * @param {String} params.name - Name of the admin
     * @param {String} params.email - Email of the admin
     * @param {String} params.password - Password entered by the admin
     * @param {String} params.confirm_password - Confirm password entered by the admin
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    register: (params) => {
        return apiServices.post('/register', params)
            .then((response) => { return response; });
    },

    /**
     * `signOut` is a method in the `AuthServices` object that handles user sign-out functionality. It
     * sends a GET request to the `/logout` endpoint using the `apiServices` helper function. If the
     * request is successful, it removes the `accessToken` cookie using the `cookieServices` helper
     * function and returns the response.
     * @param {Object} params - The params which are used for the api
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    signOut: () => {
        return apiServices.post('/logout')
            .then((response) => {
                cookieServices.remove('accessToken');
                cookieServices.remove('isDarkMode');
                cookieServices.remove('layout');
                return response;
            });
    },

    /**
     * `sendPasswordResetLink` is a method in the `AuthServices` object that sends a password reset
     * link to the user's email. It takes in an object `params` which contains the user's email. It
     * then makes a POST request to the `/send-password-reset-link` endpoint using the `apiServices`
     * helper function. If the request is successful, it returns the response.
     * @param {Object} params - The params which are used for change password api.
     * @param {String} params.email - Email of the admin user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    sendPasswordResetLink: (params) => {
        return apiServices.post('/send-password-reset-link', params)
            .then((response) => { return response; });
    },

    /**
     * `resetPassword` is a method in the `AuthServices` object that handles resetting the user's
     * password. It takes in an object `params` which contains the user's email and new password. It
     * then makes a POST request to the `/reset-password` endpoint using the `apiServices` helper
     * function. If the request is successful, it returns the response.
     * @param {Object} params - The params which are used for change password api.
     * @param {String} params.email - Email of the admin user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    resetPassword: (params) => {
        return apiServices.post('/reset-password', params)
            .then((response) => { return response; });
    }
};
