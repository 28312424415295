
import { Grid, styled } from "@mui/material";


const NotFoundStyled = styled(({ ...props }) => (<Grid  {...props} />))`
		min-height: 100vh;
 		& img {
 			width: 300px;
 			margin-bottom: ${props => props.theme.spacing(1)};
 		}
		& .text-wrong-turn {
			color: ${props => (props.theme.palette.primary.main)};
			margin-left: ${props => props.theme.spacing(2)};
		}
		& .text-not-found {
			color: ${props => (props.theme.palette.error.main)};
		}
`;

export { NotFoundStyled };
