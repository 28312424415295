import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Stack } from '@mui/material';
import { AbilityCan } from '../../../../_helpers/permission/AbilityCan';
import { postAuthRoutes } from '../../../../routes';
import { GeneralButton, RouteLink, PageHeader } from '../../../../_components';
import { ListPermissions } from '.';

function Permissions() {

    /* Destructuring the state from redux store. */
    const { user } = useSelector((state) => state.ProfileReducer);

    return (
        <Fragment>
            <Box>
                <PageHeader title={postAuthRoutes('permissions').name} action={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I='add_permission' passThrough={(user.role === 'developer') ? true : false}>
                            <RouteLink to={postAuthRoutes('create_permission').path} label={<GeneralButton label={postAuthRoutes('create_permission').name} />} />
                        </AbilityCan>
                    </Stack>
                } />
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} >
                    <ListPermissions />
                </Grid>
            </Grid>

        </Fragment>
    );
}

export { Permissions };