import React from 'react'
import { Box, styled } from '@mui/material';

const InfiniteScrollStyled = styled(({ ...props }) => (<Box {...props} />))`

    height: ${props => (props.height)};
    overflowY: scroll !important;
    padding: 0 !important;

    & .MuiAlert-root {
        justify-content: center;
    }

`;

export { InfiniteScrollStyled }
