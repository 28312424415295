import { createCanBoundTo } from "@casl/react"
import ability from "./UserAbility"

/* `const AbilityCan = createCanBoundTo(ability);` is creating a higher-order component that can be
used to check user permissions based on the `ability` object. The `createCanBoundTo` function is
provided by the `@casl/react` library and takes an `ability` object as an argument. It returns a
function that can be used to create a component that checks whether the current user has permission
to perform a certain action. The resulting `AbilityCan` component can be used in the application to
conditionally render UI elements or enable/disable certain functionality based on the user's
permissions. */
export const AbilityCan = createCanBoundTo(ability);

