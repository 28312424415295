import React from 'react'
import { styled } from '@mui/material';
import { Box } from '../../../../_components';

const ProfileDetailsStyled = styled(({ ...props }) => (<Box {...props} />))`

    & .MuiAvatar-root {
        width : ${props => `${props.theme.functions.pxToRem(150)}`};
        height : ${props => `${props.theme.functions.pxToRem(150)}`};
        font-size : ${props => props.theme.typography.d1.fontSize};
    }
    
    & .MuiGrid-container {
        display: flex;
        align-items: center;
        justify-content: center;

        & .MuiChip-label {
            text-transform:capitalize;
        }

        & .MuiTypography-root {
            display: flex;
            align-items: center;
        }
    }

`;

export { ProfileDetailsStyled }