import pxToRem from "../../../functions/pxToRem";
import borders from "../../../base/borders";
import typography from "../../../base/typography";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  // fontSize: typography.size.sm,
  fontWeight: typography.fontWeightMedium,
  borderRadius: borders.borderRadius.xl,
  padding: `${pxToRem(6)} ${pxToRem(16)}`,
  lineHeight: typography.lineHeight.md,
  textAlign: "center",
  textTransform: "uppercase",
  userSelect: "none",
  backgroundPositionX: "25% !important",
  transition: "all 150ms ease-in",

  "&:disabled": {
    pointerEvent: "none",
    opacity: 0.65,
  },

  "& .material-icons": {
    fontSize: typography.size.xs,
    marginTop: pxToRem(-2),
  },

  "& .MuiSvgIcon-root": {
    color: 'inherit'
  }
};
