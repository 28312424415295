import React, { Fragment, useEffect, useState } from 'react';
import { AddBox, CheckBox, CheckBoxOutlineBlank, ChevronRight, ExpandMore, Folder, FolderOpen, IndeterminateCheckBox, InsertDriveFile } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Grid, Stack, Chip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { UserManagementAction } from '../../../../../redux/actions';
import { postAuthRoutes } from '../../../../../routes';
import { useNavigate, useLocation } from "react-router-dom";
import { PageHeader, NoData, CancelButton, SubmitButton } from '../../../../../_components';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

function AssignRolePermission({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { search } = useLocation();
    const params = new URLSearchParams(search);
    const roleId = params.get('rId');

    /* Destructuring the state from redux store. */
    const assignPermission = (params) => dispatch(UserManagementAction.assignPermission(params));
    const getAssignedPermissions = (params) => dispatch(UserManagementAction.getAssignedPermissions(params));
    const { assigned_permissions_loading, assigned_permissions, all_permissions } = useSelector((state) => state.UserManagementReducer);

    /* Initializing the state with the default values. */
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [roleName, setRoleName] = useState('');
    const [roleStatus, setRoleStatus] = useState('');

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        getAssignedPermissions({ show_checkbox: true, role_id: roleId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleId]);

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        setExpanded(getNodeIds(all_permissions));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [all_permissions]);

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        if (Object.keys(assigned_permissions).length > 0) {
            setChecked(assigned_permissions.permissions);
            setRoleName(assigned_permissions.name);
            setRoleStatus(assigned_permissions.status);
        }
    }, [assigned_permissions]);

    /**
     * The function recursively extracts all node ids from a tree-like data structure.
     * @param {Object} data - The `data` parameter is an array of objects that represent nodes in a tree
     * structure. Each object has a `value` property that represents the node's value, and a `children`
     * property that is an array of objects representing the node's children.
     * @returns {String} The function `getNodeIds` is returning an array of node ids. The function recursively
     * traverses a tree-like data structure represented by the `data` parameter and extracts the
     * `value` property of each node, adding it to the `ids` array. If a node has children, the
     * function calls itself recursively with the `children` array as the new `data` parameter, and
     * concatenates the
     * @author Akshay N
     * @created_at 21/05/2023
     */
    function getNodeIds(data) {
        let ids = [];
        data?.forEach(({ value, children }) => {
            ids = [...ids, value, ...getNodeIds(children)];
        });
        return ids;
    }

    /**
     * The function handles the submit event of the form. It prevents the default action of the form,
     * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
     * dispatches the corresponding action
     * @param {Object} e the event object
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleSubmit(e) {
        e.preventDefault();
        dispatchAssignPermissionAction(checked);
    }

    /**
     * This function dispatches an action to assign permissions to a role asynchronously.
     * @param formData - There is no parameter named `formData` defined in the given code snippet. It
     * seems like it is missing or defined outside of the shown code.
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    async function dispatchAssignPermissionAction(formData) {
        await assignPermission({ permissions: checked, role_id: roleId });
    }

    /**
     * The function cancels the assignment of a permission and redirects to the role page.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function cancelAssignPermission() {
        let redirectPath = postAuthRoutes('role').path;
        navigate(redirectPath);
    }

    return (
        <Fragment>
            {!assigned_permissions_loading && all_permissions.length === 0 && <NoData content1={`No`} content2={`Assigned Permissions available`} minHeight={`55vh`} ></NoData>}
            {(!assigned_permissions_loading && all_permissions.length > 0) &&
                <Fragment>
                    <PageHeader title={postAuthRoutes('assign_permission').name} />
                    <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={6} >
                            <Card>
                                <CardHeader title={roleName} action={
                                    <Fragment>
                                        <Chip size='small' label={roleStatus === 'active' ? 'Active' : 'Inactive'} color={roleStatus === 'active' ? 'success' : 'error'} />
                                    </Fragment>
                                } />
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Card>
                                <form onSubmit={handleSubmit} noValidate>

                                    <CardContent>
                                        <Grid container spacing={3} justifyContent="center" alignItems="center" >
                                            <Grid item xs={12}>
                                                {(!assigned_permissions_loading && all_permissions.length > 0) &&
                                                    <CheckboxTree
                                                        nodes={all_permissions}
                                                        checked={checked}
                                                        expanded={expanded}
                                                        onCheck={(checked) => setChecked(checked)}
                                                        onExpand={(expanded) => setExpanded(expanded)}

                                                        icons={{
                                                            check: <CheckBox fontSize="inherit" />,
                                                            uncheck: <CheckBoxOutlineBlank fontSize="inherit" />,
                                                            halfCheck: <CheckBox fontSize="inherit" />,
                                                            expandClose: <ChevronRight fontSize="inherit" />,
                                                            expandOpen: <ExpandMore fontSize="inherit" />,
                                                            expandAll: <AddBox fontSize="inherit" />,
                                                            collapseAll: <IndeterminateCheckBox fontSize="inherit" />,
                                                            parentClose: <Folder fontSize="inherit" />,
                                                            parentOpen: <FolderOpen fontSize="inherit" />,
                                                            leaf: <InsertDriveFile fontSize="inherit" />
                                                        }}
                                                    />
                                                }
                                            </Grid>
                                            <Grid item xs={8} >
                                                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                    <SubmitButton label={'Assign Permissions'} loading={assigned_permissions_loading} tabIndex={6} />
                                                    <CancelButton onClick={cancelAssignPermission} tabIndex={12} />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </form>
                            </Card>
                        </Grid>
                    </Grid>

                </Fragment>}
        </Fragment>
    );
}

export { AssignRolePermission };
