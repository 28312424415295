module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {String} name Input name
     * @param {String} value Input value
     * @param {Object} [data={}] All input values stored in the state
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    validate: (name, value, data = {}) => {
        const errors = {};
        switch (name) {

            case 'status': {
                errors.status = '';
                if (value === '')
                    errors.status = 'Select Status';
                break;
            } case 'name': {
                errors.name = '';
                if (value === '')
                    errors.name = 'Enter Name';
                break;
            } case 'portal': {
                errors.portal = '';
                if (value === '')
                    errors.portal = 'Select portal';
                break;
            } default: {
                errors[name] = '';
                break;
            }

        }
        return errors;
    }
};