import React from 'react';
import { Box, styled } from '@mui/material';

const SignInStyled = styled(({ ...props }) => <Box {...props} />)`
	& .caption {
        & .route-name{
            text-transform: lowercase;
        }
	};
`;

export { SignInStyled };