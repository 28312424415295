
import typography from "../../base/typography";
import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      padding: pxToRem(16),
      fontSize: typography.size.md,
      paddingTop: `${pxToRem(25)} !important`,
    },
  },
  variants: []
};
