import { GlobalAction, failure, request, success } from ".";
import { MentorServices } from "../services";

export const GET_MENTOR_LIST_REQUEST = 'GET_MENTOR_LIST_REQUEST';
export const GET_MENTOR_LIST_SUCCESS = 'GET_MENTOR_LIST_SUCCESS';
export const GET_MENTOR_LIST_FAILURE = 'GET_MENTOR_LIST_FAILURE';

export const CHANGE_MENTOR_STATUS_REQUEST = 'CHANGE_MENTOR_STATUS_REQUEST';
export const CHANGE_MENTOR_STATUS_SUCCESS = 'CHANGE_MENTOR_STATUS_SUCCESS';
export const CHANGE_MENTOR_STATUS_FAILURE = 'CHANGE_MENTOR_STATUS_FAILURE';

export const GET_MENTOR_DETAILS_REQUEST = 'GET_MENTOR_DETAILS_REQUEST';
export const GET_MENTOR_DETAILS_SUCCESS = 'GET_MENTOR_DETAILS_SUCCESS';
export const GET_MENTOR_DETAILS_FAILURE = 'GET_MENTOR_DETAILS_FAILURE';

export const RESET_MENTOR_PROFILE_REQUEST = 'RESET_MENTOR_PROFILE_REQUEST';
export const RESET_MENTOR_PROFILE_SUCCESS = 'RESET_MENTOR_PROFILE_SUCCESS';
export const RESET_MENTOR_PROFILE_FAILURE = 'RESET_MENTOR_PROFILE_FAILURE';

export const MentorAction = {

    /**
     * This code block defines an action creator function `getMentorList` that dispatches an asynchronous action to get a list of email logs.
     * It takes in `params` as an argument, which contains the necessary data to fetch the email logs.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at
     */
    getMentorList: (params) => {
        return dispatch => {
            dispatch(request(GET_MENTOR_LIST_REQUEST, params));
            return MentorServices.getMentorList(params)
                .then(
                    response => { return dispatch(success(GET_MENTOR_LIST_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_MENTOR_LIST_FAILURE, params, error)); }
                );
        };
    },
    /**
     * This code block defines an action creator function `getMentorList` that dispatches an asynchronous action to get a list of email logs.
     * It takes in `params` as an argument, which contains the necessary data to fetch the email logs.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at
     */
    changeMentorStatus: (params) => {
        return dispatch => {
            dispatch(request(CHANGE_MENTOR_STATUS_REQUEST, params));
            return MentorServices.changeMentorStatus(params)
                .then(
                    response => { return dispatch(success(CHANGE_MENTOR_STATUS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(CHANGE_MENTOR_STATUS_FAILURE, params, error)); }
                );
        };
    },
    /**
     * This code block defines an action creator function `getMentorList` that dispatches an asynchronous action to get a list of email logs.
     * It takes in `params` as an argument, which contains the necessary data to fetch the email logs.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at
     */
    getMentorDetails: (params) => {
        return dispatch => {
            dispatch(request(GET_MENTOR_DETAILS_REQUEST, params));
            return MentorServices.getMentorDetails(params)
                .then(
                    response => { return dispatch(success(GET_MENTOR_DETAILS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_MENTOR_DETAILS_FAILURE, params, error)); }
                );
        };

    },
    /**
     * This code block defines an action creator function `getMentorList` that dispatches an asynchronous action to get a list of email logs.
     * It takes in `params` as an argument, which contains the necessary data to fetch the email logs.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at
     */
    resetMentorProfile: (params) => {
        return dispatch => {
            dispatch(request(RESET_MENTOR_PROFILE_REQUEST, params));
            return MentorServices.resetMentorProfile(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(RESET_MENTOR_PROFILE_SUCCESS, params, response)); },
                    error => { return dispatch(failure(RESET_MENTOR_PROFILE_FAILURE, params, error)); }
                );
        };

    },

};
