import React from 'react';
import { FormContentStyled } from '.';

function FormContent({ ...props }) {

    return (
        <FormContentStyled  {...props}>
            {props.children}
        </FormContentStyled>
    );
}

export { FormContent };
