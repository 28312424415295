import React, { useMemo, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/system";
import { CssBaseline } from "@mui/material";
import { Snackbar } from "./_components";
import { PageLoader, InitialLoader } from "./_components/loader";
import { getBrowserRouter } from "./routes";
import { AppNameContext } from "./_contexts";
import { APP_NAME } from "./_helpers";
import themeLight from "./_theme/theme-light";
import themeDark from "./_theme/theme-dark";

function App() {

	/* Destructuring the state from redux store. */
	const { pageLoader, initialLoader, toastMessage: toast, theme } = useSelector((state) => state.GlobalReducer);

	/* Initializing the state with the default values. */
	const [isDarkMode, setIsDarkMode] = useState(false);

	/* Setting the theme based on the user's preference. */
	// const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
	const prefersDarkMode = false;
	useMemo(() => setIsDarkMode(theme === "dark" ? true : theme === "light" ? false : prefersDarkMode), [prefersDarkMode, theme]);

	return (
		<AppNameContext.Provider value={APP_NAME}>
			<HelmetProvider>
				<ThemeProvider theme={isDarkMode ? themeDark : themeLight}>
					<CssBaseline />
					<RouterProvider router={createBrowserRouter(getBrowserRouter())} />
					<PageLoader loader={pageLoader} />
					<InitialLoader loader={initialLoader} />
					<Snackbar message={toast} />
				</ThemeProvider>
			</HelmetProvider>
		</AppNameContext.Provider>
	);
}

export { App };
