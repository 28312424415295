import { Box, Grid } from '@mui/material';
import React, { Fragment } from 'react';
import { ListMentors } from './list-mentors';


function Mentors() {
    return (
        <Fragment>
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <ListMentors />
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    );
}

export { Mentors };