import { apiServices, cookieServices } from '../../_helpers';


export const ProfileServices = {

    /**
     * This is a service function in JavaScript that makes an API call to retrieve user details from
     * the '/profile' endpoint. It then sets a cookie named 'isDarkMode' with the value of the
     * 'is_dark_mode' property from the API response, and sets an expiration date for the cookie.
     * Finally, it returns the API response.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    getUserDetails: () => {
        return apiServices.get('/profile')
            .then((response) => {
                let date = new Date();
                date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
                const options = { path: '/', expires: date };
                cookieServices.set('isDarkMode', response.data.is_dark_mode, options);
                cookieServices.set('layout', response.data.layout, options);
                return response;
            });
    },

    /**
     * `getUserPermission` is a service function that makes an API call to retrieve user permissions
     * from the `/profile/permissions` endpoint. It returns the API response.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    getUserPermission: () => {
        return apiServices.get('/profile/permissions')
            .then((response) => { return response; });
    },

    /**
     * This is a service function in JavaScript that makes an API call to update the user's theme
     * preference by sending a PUT request to the '/profile/update/theme' endpoint. It then sets a
     * cookie named 'isDarkMode' with the value of the 'is_dark_mode' property from the API response,
     * and sets an expiration date for the cookie. Finally, it returns the API response.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    updateTheme: () => {
        return apiServices.put('/profile/update/theme')
            .then((response) => {
                let date = new Date();
                date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
                const options = { path: '/', expires: date };
                cookieServices.set('isDarkMode', response.data.is_dark_mode, options);
                cookieServices.set('layout', response.data.layout, options);
                return response;
            });
    },


    updateLayout: () => {
        return apiServices.put('/profile/update/theme/layout')
            .then((response) => {
                let date = new Date();
                date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
                const options = { path: '/', expires: date };
                cookieServices.set('layout', response.data.layout, options);
                return response;
            });
    },

    /**
     * `updateUserDetails` is a service function that takes an object `params` as input, which contains
     * the user's updated details such as name, email, and mobile number. It then makes an API call to
     * the `/profile/update` endpoint with a PUT request, passing the `params` object as the request
     * body. Finally, it returns the API response. This function is used to update the user's profile
     * details.
     * @param {Object} params - The params which are used fr the api.
     * @param {String} params.name - Name of the user
     * @param {String} params.email - Email of the user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    updateUserDetails: (params) => {
        return apiServices.put('/profile/update', params)
            .then((response) => { return response; });
    },

    /**
     * `changePassword` is a service function that takes an object `params` as input, which contains
     * the user's current password and new password. It then makes an API call to the
     * `/profile/change-password` endpoint with a PUT request, passing the `params` object as the
     * request body. Finally, it returns the API response. This function is used to change the user's
     * password.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    changePassword: (params) => {
        return apiServices.put(`/profile/change-password`, params)
            .then((response) => {
                cookieServices.remove('accessToken');
                cookieServices.remove('isDarkMode');
                cookieServices.remove('layout');
                return response;
            });
    },

    /**
     * `getActivityList` is a service function that takes an object `params` as input, which contains
     * the parameters for the API call. It then makes a POST request to the
     * `/profile/activity-log/list` endpoint with the `params` object as the request body. Finally, it
     * returns the API response, which contains a list of the user's activity logs. This function is
     * used to retrieve the user's activity log list.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getActivityList: (params) => {
        return apiServices.get('/profile/activity-log/list', { params })
            .then((response) => { return response; });
    },

    /**
     * `getNotifications` is a service function that takes an object `params` as input, which
     * contains the parameters for the API call. It then makes a POST request to the
     * `/profile/notifications/list` endpoint with the `params` object as the request body. Finally, it
     * returns the API response, which contains a list of the user's notifications. This function is
     * used to retrieve the user's notification list.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getNotifications: (params) => {
        return apiServices.get('/notifications/list', { params })
            .then((response) => { return response; });
    },

    /**
     * `markAllRead` is a service function that sends a PUT request to the
     * `/profile/notifications/mark-all-read` endpoint to mark all notifications as read. It returns
     * the API response.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    markAllRead: () => {
        return apiServices.put('/notifications/mark-all-read')
            .then((response) => { return response; });
    },

    /**
     * `markRead` is a service function that takes an object `params` as input, which contains the
     * parameters for the API call. It then makes a PUT request to the
     * `/profile/notifications/mark-read` endpoint with the `params` object as the request body.
     * Finally, it returns the API response, which indicates whether the notification was successfully
     * marked as read. This function is used to mark a specific notification as read.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    markRead: (params) => {
        return apiServices.put('/notifications/mark-read', params)
            .then((response) => { return response; });
    },

    /**
     *  The `deleteAccount` function is a service function that sends a PUT request to the
     * `/profile/delete` endpoint to delete the user's account. It then removes the access token cookie
     * by calling the `cookieServices.remove('accessToken')` function. Finally, it returns the API
     * response.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    deleteAccount: () => {
        return apiServices.put('/profile/delete')
            .then((response) => { cookieServices.remove('accessToken'); return response; });
    },

    /**
     * The `logout` function is a service function that sends a GET request to the `/logout` endpoint
     * to log the user out. It then removes the access token cookie by calling the
     * `cookieServices.remove('accessToken')` function. Finally, it returns the API response. This
     * function is used to log the user out of the application.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    logout: () => {
        return apiServices.get('/logout')
            .then((response) => {
                cookieServices.remove('accessToken');
                return response;
            });
    }
};
