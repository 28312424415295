import { postAuthRoutes } from "../../../routes"
import { PageHeader } from "../../../_components"
import React, { Fragment } from "react"

function Dashboard() {
    return (
        <Fragment>
            <PageHeader title={`${postAuthRoutes('home').name}`} />
        </Fragment>
    )
}

export { Dashboard }
