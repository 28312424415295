import { CardHeader, styled } from '@mui/material'
import React from 'react'

const FormHeaderStyled = styled(({ ...props }) => <CardHeader {...props} />)`
	padding: 0rem;
	padding-top: ${(props) => props.theme.spacing(1)};
	padding-bottom: ${(props) => props.theme.spacing(2)} !important;
`

export { FormHeaderStyled }
