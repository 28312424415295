import { failure, request, success } from ".";
import { UserManagementServices } from "../services";
import { GlobalAction } from "./GlobalAction";

export const CREATE_UPDATE_USER_REQUEST = 'CREATE_UPDATE_USER_REQUEST';
export const CREATE_UPDATE_USER_SUCCESS = 'CREATE_UPDATE_USER_SUCCESS';
export const CREATE_UPDATE_USER_FAILURE = 'CREATE_UPDATE_USER_FAILURE';

export const CHANGE_USER_STATUS_REQUEST = 'CHANGE_USER_STATUS_REQUEST';
export const CHANGE_USER_STATUS_SUCCESS = 'CHANGE_USER_STATUS_SUCCESS';
export const CHANGE_USER_STATUS_FAILURE = 'CHANGE_USER_STATUS_FAILURE';

export const GET_USERS_LIST_REQUEST = 'GET_USERS_LIST_REQUEST';
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
export const GET_USERS_LIST_FAILURE = 'GET_USERS_LIST_FAILURE';

export const ROLES_REQUEST = 'ROLES_REQUEST';
export const ROLES_SUCCESS = 'ROLES_SUCCESS';
export const ROLES_FAILURE = 'ROLES_FAILURE';

export const CREATE_UPDATE_ROLE_REQUEST = 'CREATE_UPDATE_ROLE_REQUEST';
export const CREATE_UPDATE_ROLE_SUCCESS = 'CREATE_UPDATE_ROLE_SUCCESS';
export const CREATE_UPDATE_ROLE_FAILURE = 'CREATE_UPDATE_ROLE_FAILURE';

export const CHANGE_ROLE_STATUS_REQUEST = 'CHANGE_ROLE_STATUS_REQUEST';
export const CHANGE_ROLE_STATUS_SUCCESS = 'CHANGE_ROLE_STATUS_SUCCESS';
export const CHANGE_ROLE_STATUS_FAILURE = 'CHANGE_ROLE_STATUS_FAILURE';

export const ROLES_SELECT_LIST_REQUEST = 'ROLES_SELECT_LIST_REQUEST';
export const ROLES_SELECT_LIST_SUCCESS = 'ROLES_SELECT_LIST_SUCCESS';
export const ROLES_SELECT_LIST_FAILURE = 'ROLES_SELECT_LIST_FAILURE';

export const ASSIGN_ROLE_PERMISSION_REQUEST = 'ASSIGN_ROLE_PERMISSION_REQUEST';
export const ASSIGN_ROLE_PERMISSION_SUCCESS = 'ASSIGN_ROLE_PERMISSION_SUCCESS';
export const ASSIGN_ROLE_PERMISSION_FAILURE = 'ASSIGN_ROLE_PERMISSION_FAILURE';

export const GET_ASSIGNED_PERMISSIONS_REQUEST = 'GET_ASSIGNED_PERMISSIONS_REQUEST';
export const GET_ASSIGNED_PERMISSIONS_SUCCESS = 'GET_ASSIGNED_PERMISSIONS_SUCCESS';
export const GET_ASSIGNED_PERMISSIONS_FAILURE = 'GET_ASSIGNED_PERMISSIONS_FAILURE';

export const CREATE_UPDATE_PERMISSION_REQUEST = 'CREATE_UPDATE_PERMISSION_REQUEST';
export const CREATE_UPDATE_PERMISSION_SUCCESS = 'CREATE_UPDATE_PERMISSION_SUCCESS';
export const CREATE_UPDATE_PERMISSION_FAILURE = 'CREATE_UPDATE_PERMISSION_FAILURE';

export const CHANGE_PERMISSION_STATUS_REQUEST = 'CHANGE_PERMISSION_STATUS_REQUEST';
export const CHANGE_PERMISSION_STATUS_SUCCESS = 'CHANGE_PERMISSION_STATUS_SUCCESS';
export const CHANGE_PERMISSION_STATUS_FAILURE = 'CHANGE_PERMISSION_STATUS_FAILURE';

export const GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE';

export const PERMISSIONS_SELECT_LIST_REQUEST = 'PERMISSIONS_SELECT_LIST_REQUEST';
export const PERMISSIONS_SELECT_LIST_SUCCESS = 'PERMISSIONS_SELECT_LIST_SUCCESS';
export const PERMISSIONS_SELECT_LIST_FAILURE = 'PERMISSIONS_SELECT_LIST_FAILURE';


export const UserManagementAction = {

    /**
     * The above code is defining an action creator function called "addUser" that returns a function
     * that takes a "dispatch" argument. When this function is called, it dispatches an action of type
     * "ADD_USER_REQUEST" with the provided "params" as the payload. It then calls a service function
     * called "UserManagementServices.addUser" with the same "params" and returns a promise. If the
     * promise resolves successfully, it dispatches an action of type "ADD_USER_SUCCESS" with the
     * original "params" and the response data as the payload. If the promise is rejected, it
     * dispatches
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    addUser: (params) => {

        return dispatch => {
            dispatch(request(CREATE_UPDATE_USER_REQUEST, params));
            return UserManagementServices.addUser(params)
                .then(
                    response => { return dispatch(success(CREATE_UPDATE_USER_SUCCESS, params, response)); },
                    error => { return dispatch(failure(CREATE_UPDATE_USER_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining an action creator function called "updateUser" that returns a
     * function that takes a "dispatch" argument. When this function is called, it dispatches a
     * "request" action with the provided "params" object, then calls a service function called
     * "updateUser" with the same "params" object. If the service call is successful, it dispatches a
     * "success" action with the original "params" object and the response data. If the service call
     * fails, it dispatches a "failure" action with the original "params" object and the error data.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    updateUser: (params) => {

        return dispatch => {
            dispatch(request(CREATE_UPDATE_USER_REQUEST, params));
            return UserManagementServices.updateUser(params)
                .then(
                    response => { return dispatch(success(CREATE_UPDATE_USER_SUCCESS, params, response)); },
                    error => { return dispatch(failure(CREATE_UPDATE_USER_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `changeUserStatus` that takes in a parameter called
     * `params`. This function returns another function that takes in a parameter called `dispatch`.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    changeUserStatus: (params) => {

        return dispatch => {
            dispatch(request(CHANGE_USER_STATUS_REQUEST, params));
            return UserManagementServices.changeUserStatus(params)
                .then(
                    response => { return dispatch(success(CHANGE_USER_STATUS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(CHANGE_USER_STATUS_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `getUserList` which returns a function that takes a
     * `dispatch` parameter. This function dispatches three actions (`request`, `success`, and
     * `failure`) and returns the result of calling `UserManagementServices.getUsersList(params)`. The
     * `request` action is dispatched immediately with the `params` argument passed to `getUserList`.
     * The `success` action is dispatched if the `getUsersList` call is successful, with the `params`
     * argument, the `response.message`, `response.data`, `response.status`, and `response.status_code`
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getUserList: (params) => {

        return dispatch => {
            dispatch(request(GET_USERS_LIST_REQUEST, params));
            return UserManagementServices.getUsersList(params)
                .then(
                    response => { return dispatch(success(GET_USERS_LIST_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_USERS_LIST_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `createRole` which takes in a parameter called
     * `params`. This function returns another function that takes in a parameter called `dispatch`.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    createRole: (params) => {

        return dispatch => {
            dispatch(request(CREATE_UPDATE_ROLE_REQUEST, params));
            return UserManagementServices.createRole(params)
                .then(
                    response => { return dispatch(success(CREATE_UPDATE_ROLE_SUCCESS, params, response)); },
                    error => { return dispatch(failure(CREATE_UPDATE_ROLE_FAILURE, params, error)); }
                );
        };


    },

    /**
     * The above code is defining an action creator function called "UpdateRole" that returns a
     * function that dispatches actions to update a user's role. It takes in a parameter called
     * "params" which contains the necessary information to update the user's role. The function
     * dispatches a "request" action, then calls a service function to update the user's role. If the
     * service call is successful, it dispatches a "success" action with the response data and a toast
     * message. If the service call fails, it dispatches a "failure" action with the error data and
     * message.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    UpdateRole: (params) => {

        return dispatch => {
            dispatch(request(CREATE_UPDATE_ROLE_REQUEST, params));
            return UserManagementServices.updateRole(params)
                .then(
                    response => { return dispatch(success(CREATE_UPDATE_ROLE_SUCCESS, params, response)); },
                    error => { return dispatch(failure(CREATE_UPDATE_ROLE_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `changeRoleStatus` that returns a function that
     * takes a `dispatch` parameter. This function dispatches three different actions based on the
     * result of a call to `UserManagementServices.changeRoleStatus(params)`. The actions dispatched
     * are `CHANGE_ROLE_STATUS_REQUEST`, `CHANGE_ROLE_STATUS_SUCCESS`, and
     * `CHANGE_ROLE_STATUS_FAILURE`. The `request` action is dispatched first, followed by either the
     * `success` or `failure` action depending on whether the call to
     * `UserManagementServices.changeRoleStatus(params)` was successful or not. Finally, the
     * `GlobalAction
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    changeRoleStatus: (params) => {

        return dispatch => {
            dispatch(request(CHANGE_ROLE_STATUS_REQUEST, params));
            return UserManagementServices.changeRoleStatus(params)
                .then(
                    response => { return dispatch(success(CHANGE_ROLE_STATUS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(CHANGE_ROLE_STATUS_FAILURE, params, error)); }
                );
        };

    },

    /**
    * The above code is defining a function called `listRoles` that returns a function that takes a
    * `dispatch` parameter. The returned function dispatches an action with a `request` parameter and
    * then calls a service function called `listRoles` with the `params` parameter. If the service
    * function call is successful, it dispatches a `success` action with the `request` and `response`
    * parameters. If the service function call fails, it dispatches a `failure` action with the
    * `request` and `error` parameters. The `request`, `success`, and `failure` functions
    * @param {null}
    * @return {null}
    * @author Akshay N
    * @created_at 22/05/2023
    */
    listRoles: (params) => {

        return dispatch => {
            dispatch(request(ROLES_REQUEST, params));
            return UserManagementServices.listRoles(params)
                .then(
                    response => { return dispatch(success(ROLES_SUCCESS, params, response)); },
                    error => { return dispatch(failure(ROLES_FAILURE, params, error)); }
                );
        };

    },


    /**
     * The above code is defining a function called `getRoleSelectList` that returns a function that
     * takes a `dispatch` parameter. This function dispatches an action with a `request` object and
     * then calls a service function `UserManagementServices.getRoleSelectList` with the `params`
     * parameter. If the service function call is successful, it dispatches a `success` action with the
     * `params` and `response` objects. If the service function call fails, it dispatches a `failure`
     * action with the `params` and `error` objects. The `request`, `success`,
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getRoleSelectList: (params) => {

        return dispatch => {
            dispatch(request(ROLES_SELECT_LIST_REQUEST, params));
            return UserManagementServices.getRoleSelectList(params)
                .then(
                    response => { return dispatch(success(ROLES_SELECT_LIST_SUCCESS, params, response)); },
                    error => { return dispatch(failure(ROLES_SELECT_LIST_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `assignPermission` which takes in a parameter
     * called `params`. This function returns another function that takes in a parameter called
     * `dispatch`.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    assignPermission: (params) => {

        return dispatch => {
            dispatch(request(ASSIGN_ROLE_PERMISSION_REQUEST, params));
            return UserManagementServices.assignPermission(params)
                .then(
                    response => { return dispatch(success(ASSIGN_ROLE_PERMISSION_SUCCESS, params, response)); },
                    error => { return dispatch(failure(ASSIGN_ROLE_PERMISSION_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `getAssignedPermissions` that returns a function
     * that takes a `dispatch` parameter. This function dispatches an action with a `request` parameter
     * and then calls a service function called `getAssignedPermissions` with the `params` parameter.
     * If the service function call is successful, it dispatches a `success` action with the `params`
     * and `response` parameters. If the service function call fails, it dispatches a `failure` action
     * with the `params` and `error` parameters. The `request`, `success`, and `failure`
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getAssignedPermissions: (params) => {

        return dispatch => {
            dispatch(request(GET_ASSIGNED_PERMISSIONS_REQUEST, params));
            return UserManagementServices.getAssignedPermissions(params)
                .then(
                    response => { return dispatch(success(GET_ASSIGNED_PERMISSIONS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_ASSIGNED_PERMISSIONS_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining an action creator function called `addPermission` that returns a
     * function that accepts a `dispatch` argument. When this function is called, it dispatches a
     * `request` action with the `params` argument, then calls an asynchronous function
     * `UserManagementServices.addPermission(params)` to add a permission. If the function call is
     * successful, it dispatches a `success` action with the `params` and `response` arguments, and
     * also dispatches a `showToastMessage` action with the `response.message` argument. If the
     * function call fails, it dispatches a
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    addPermission: (params) => {

        return dispatch => {
            dispatch(request(CREATE_UPDATE_PERMISSION_REQUEST, params));
            return UserManagementServices.addPermission(params)
                .then(
                    response => { return dispatch(success(CREATE_UPDATE_PERMISSION_SUCCESS, params, response)); },
                    error => { return dispatch(failure(CREATE_UPDATE_PERMISSION_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining an action creator function called `updatePermission` which takes in a
     * `params` object as a parameter. This function returns another function that takes in a
     * `dispatch` parameter.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    updatePermission: (params) => {

        return dispatch => {
            dispatch(request(CREATE_UPDATE_PERMISSION_REQUEST, params));
            return UserManagementServices.updatePermission(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(CREATE_UPDATE_PERMISSION_SUCCESS, params, response)); },
                    error => { return dispatch(failure(CREATE_UPDATE_PERMISSION_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `changePermissionStatus` that returns a function
     * that dispatches actions to change the permission status of a user. It takes in a `params` object
     * as a parameter and returns a function that dispatches a `request` action, calls a service
     * function to change the permission status, and then dispatches either a `success` or `failure`
     * action depending on the result of the service call. The `request`, `success`, and `failure`
     * actions are defined as inner functions that return an object with a `type` property and
     * additional data related to the
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    changePermissionStatus: (params) => {

        return dispatch => {
            dispatch(request(CHANGE_PERMISSION_STATUS_REQUEST, params));
            return UserManagementServices.changePermissionStatus(params)
                .then(
                    response => { return dispatch(success(CHANGE_PERMISSION_STATUS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(CHANGE_PERMISSION_STATUS_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `getPermissionsList` that returns a function that
     * takes a `dispatch` parameter. This function dispatches three actions: `request`, `success`, and
     * `failure`. The `request` action is dispatched immediately with the `params` argument passed to
     * `getPermissionsList`. The function then calls `UserManagementServices.getPermissionsList` with
     * the same `params` argument. If the call is successful, the `success` action is dispatched with
     * the `params` argument, the `response.message`, `response.data`, `response.status`, and
     * `response.status
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getPermissionsList: (params) => {

        return dispatch => {
            dispatch(request(GET_PERMISSIONS_REQUEST, params));
            return UserManagementServices.getPermissionsList(params)
                .then(
                    response => { return dispatch(success(GET_PERMISSIONS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_PERMISSIONS_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `permissionSelectList` that returns a function that
     * dispatches an action to request a list of permissions from a server using
     * `UserManagementServices.permissionSelectList(params)`. The function returns a promise that
     * resolves with a success action containing the response data or a failure action containing the
     * error data. The success and failure actions contain information about the request, response, and
     * error.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    permissionSelectList: (params) => {

        return dispatch => {
            dispatch(request(PERMISSIONS_SELECT_LIST_REQUEST, params));
            return UserManagementServices.permissionSelectList(params)
                .then(
                    response => { return dispatch(success(PERMISSIONS_SELECT_LIST_SUCCESS, params, response)); },
                    error => { return dispatch(failure(PERMISSIONS_SELECT_LIST_FAILURE, params, error)); }
                );
        };

    },

};
