import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { postAuthRoutes } from "../../../../../routes";
import { getPortals, handleInputChange, validateForm } from "../../../../../_helpers";
import { FormInput, CancelButton, SubmitButton, Form, FormHeader, FormContent, FormAction, Animate, FormSelect } from "../../../../../_components";
import { Grid, } from "@mui/material";
import { UserManagementAction } from "../../../../../redux/actions";
import { validate } from "./validate";

// Initialize form input values to null
const inputs = { name: '', portal: '' };


function CreateUpdateRole() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { role } = location.state ?? {};

    /* Destructuring the state from redux store. */
    const portals = getPortals();
    const createRole = (params) => dispatch(UserManagementAction.createRole(params));
    const UpdateRole = (params) => dispatch(UserManagementAction.UpdateRole(params));
    const { create_update_role_loading, create_update_role_errors } = useSelector((state) => state.UserManagementReducer);

    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });
    const [response, setResponse] = useState({});

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        setErrors({ ...create_update_role_errors });
    }, [create_update_role_errors]);

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        if (role && role._id)
            setData({ role_id: role._id, name: role.name, portal: role.portal });
    }, [role]);

    /**
     * It takes the name and value of the input, validates it, and sets the errors and data state
     * @param {Object} e form object
     * @param {String} e.name input name
     * @param {String} e.value input value
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        /* Validating the input and setting the errors state. */
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * The function handles the submit event of the form. It prevents the default action of the form,
     * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
     * dispatches the corresponding action
     * @param {Object} e the event object
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        const formData = { "role_id": data.role_id, "name": data.name, "portal": data.portal };
        addRole(formData);

    }

    /**
     * The function adds a role and redirects to the role page if successful.
     * @param {Object} formData - The `formData` parameter is likely an object containing data that is used to
     * create or update a role. It could include properties such as the role name, description,
     * permissions, etc. The exact properties and values would depend on the specific implementation of
     * the `createRole` and `UpdateRole` functions
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    async function addRole(formData) {
        const isCreated = role && role._id ? await UpdateRole(formData) : await createRole(formData);
        setResponse(isCreated);
    }

    /**
     * The function redirects the user to the "role" page after cancelling an action.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function cancelRole() {
        let redirectPath = postAuthRoutes('role').path;
        navigate(redirectPath);
    }

    useEffect(() => {
        if (response && response.status === 1) {
            let redirectPath = postAuthRoutes('role').path;
            navigate(redirectPath);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);


    return (
        <Animate>
            <Grid container spacing={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                <Grid item xs={4}>
                    <Form>
                        <FormHeader title={role && role._id ? postAuthRoutes('update_role').name : postAuthRoutes('create_role').name} />
                        <form onSubmit={handleSubmit} noValidate>
                            <FormContent >
                                <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center">
                                    <Grid item xs={12} >
                                        <FormSelect
                                            tabIndex={1}
                                            label={`Portal`}
                                            name={`portal`}
                                            placeholder={`Select portal`}
                                            onChange={handleChange}
                                            data={portals}
                                            error={action.isSubmitted && errors.portal ? errors.portal : ''}
                                            className="col-md-6"
                                            value={data.portal}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormInput
                                            tabIndex={2}
                                            label='Name'
                                            name='name'
                                            value={data.name}
                                            error={(action.isSubmitted && (errors.name)) ? (errors.name) : ''}
                                            onChange={handleChange}
                                            maxLength={250}
                                        />
                                    </Grid>
                                </Grid>
                            </FormContent>
                            <FormAction>
                                <CancelButton onClick={cancelRole} tabIndex={4} loading={create_update_role_loading} />
                                <SubmitButton tabIndex={3} label={role && role._id ? 'Update' : 'Create'} loading={create_update_role_loading} />
                            </FormAction>
                        </form>
                    </Form>
                </Grid>
            </Grid>
        </Animate>
    );

}

export { CreateUpdateRole };