import axios from "axios"
import { cookieServices } from ".";
import { API_ENDPOINT, API_REQUEST_TIMEOUT } from "./config"

/* `const HEADERS` is an object that contains the headers that will be sent with each API request made
using the `apiServices` instance of the Axios library. The headers specify the format of the data
being sent and received (`Content-type` and `Accept`), as well as allow cross-origin resource
sharing (`access-control-allow-origin`) and specify which headers are allowed in the request
(`access-control-allow-headers`). */
const HEADERS = {
    'Content-type': 'application/json',
    'Accept': 'application/json',
    'access-control-allow-origin': '*',
    'access-control-allow-headers': '*'
}

/* This code creates an instance of the Axios library called `apiServices` with a specified `baseURL`,
`timeout`, and `headers`. The `baseURL` specifies the base URL for all API requests made using this
instance, while the `timeout` specifies the maximum amount of time to wait for a response from the
server. The `headers` object specifies the headers that will be sent with each API request,
including the format of the data being sent and received, cross-origin resource sharing, and allowed
headers. */
const apiServices = axios.create({
    baseURL: API_ENDPOINT,
    timeout: API_REQUEST_TIMEOUT,
    headers: HEADERS
});

/* This code is adding an interceptor to the Axios instance `apiServices` that will modify the request
configuration before it is sent to the server. Specifically, it is checking if there is an access
token stored in a cookie using the `cookieServices.get()` method. If an access token is found, it is
added to the request headers as an authorization token using the `Authorization` key and the
`Bearer` scheme. If there is no access token, the original request configuration is returned. If
there is an error, the interceptor returns a rejected promise with the error. */
apiServices.interceptors.request.use(
    config => {
        if (cookieServices.get('accessToken')) {
            config.headers.Authorization = `Bearer ${cookieServices.get('accessToken')}`;
        }
        return config;
    },
    error => { Promise.reject(error) }
);

/* This code is adding an interceptor to the Axios instance `apiServices` that will modify the response
configuration before it is returned to the calling code. Specifically, it is checking if the
response is successful or if there is an error. If the response is successful, it extracts the
status code, data, errors, message, and status from the response object and returns them in a new
object. If there is an error, it extracts the status code, data, errors, message, and status from
the error response object and returns them in a rejected promise. This allows for consistent
handling of responses and errors throughout the application. */
apiServices.interceptors.response.use(
    (res) => {
        const statusCode = res.status;
        const result = res.data;
        return { errors: result.errors, data: result.data, status_code: statusCode, message: result.message, status: result.status };
    },
    (err) => {
        const response = err.response;
        const statusCode = response.status;
        const result = response.data;
        return Promise.reject({ errors: result.errors, data: result.data, status_code: statusCode, message: result.message, status: result.status });
    }
);

export { apiServices }
