import inputOutlined from "../../../_root/components/form/inputOutlined";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...inputOutlined,
  styleOverrides: {
    ...inputOutlined.styleOverrides,
    root: {
      ...inputOutlined.styleOverrides.root,
      backgroundColor: colors.transparent,

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.grey[400]
      },

      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.primary.main
      },

      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          // borderColor: colors.primary.main,
        }
      }
    },

    notchedOutline: {
      ...inputOutlined.styleOverrides.notchedOutline,
      borderColor: colors.grey[300]
    },

    input: {
      ...inputOutlined.styleOverrides.input,
      color: colors.text.main,
      backgroundColor: colors.transparent
    },
    multiline: {
      ...inputOutlined.styleOverrides.multiline,
      color: colors.text.main
    }
  },
};
