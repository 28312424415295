import {
    GET_MENTOR_LIST_REQUEST, GET_MENTOR_LIST_SUCCESS, GET_MENTOR_LIST_FAILURE,
    CHANGE_MENTOR_STATUS_REQUEST, CHANGE_MENTOR_STATUS_SUCCESS, CHANGE_MENTOR_STATUS_FAILURE,
    GET_MENTOR_DETAILS_REQUEST, GET_MENTOR_DETAILS_SUCCESS, GET_MENTOR_DETAILS_FAILURE,
    RESET_MENTOR_PROFILE_REQUEST, RESET_MENTOR_PROFILE_SUCCESS, RESET_MENTOR_PROFILE_FAILURE
} from '../actions';

const initialState = {
    mentors_loading: false, mentors: [], mentors_errors: {}, mentors_total: 0, mentors_total_pages: 0, mentors_per_page: 10, mentors_page: 1,
    change_mentor_status_loading: false, change_mentor_status: {}, change_mentor_status_errors: {},
    mentor_loading: true, mentor: {}, mentor_errors: {}
};

export function MentorReducer(state = initialState, action) {
    switch (action.type) {
        //  begin::Get email logs list redux
        case GET_MENTOR_LIST_REQUEST: {
            return {
                ...state,
                mentors_loading: true,
                mentors_errors: false,
            };
        }
        case GET_MENTOR_LIST_SUCCESS: {
            let newArray = [...state.mentors]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.mentors.forEach((payload) => {
                const mentorIndex = newArray.findIndex(mentor => mentor._id === payload._id);
                if (mentorIndex !== -1) {
                    newArray[mentorIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                mentors_loading: false,
                mentors: newArray,
                mentors_errors: {},
                mentors_page: action.data.page,
                mentors_per_page: action.data.per_page,
                mentors_total: action.data.total,
                mentors_total_pages: action.data.total_pages
            };
        }
        case GET_MENTOR_LIST_FAILURE: {
            return {
                ...state,
                mentors_loading: false,
                mentors_errors: action.errors,
            };
        }
        // begin::Get email logs list redux


        //  begin::get user redux
        case CHANGE_MENTOR_STATUS_REQUEST: {
            return {
                ...state,
                change_mentor_status_loading: true,
                change_mentor_status: {}
            };
        }
        case CHANGE_MENTOR_STATUS_SUCCESS: {

            let mentorsArray = [...state.mentors];
            const mentorIndex = mentorsArray.findIndex(mentor => mentor._id === action.data._id);
            if (mentorIndex !== -1) {
                mentorsArray[mentorIndex] = action.data;
            } else {
                mentorsArray = mentorsArray.concat(action.data);
            }

            return {
                ...state,
                change_mentor_status_loading: false,
                change_mentor_status: action.data,
                change_mentor_status_errors: {},
                mentors: mentorsArray
            };
        }
        case CHANGE_MENTOR_STATUS_FAILURE: {
            return {
                ...state,
                change_mentor_status_loading: false,
                change_mentor_status_errors: action.errors,
            };
        }
        // begin::get user redux

        //  begin::get user redux
        case GET_MENTOR_DETAILS_REQUEST: {
            return {
                ...state,
                mentor_loading: true,
                mentor: {}
            };
        }
        case GET_MENTOR_DETAILS_SUCCESS: {

            let mentorsArray = [...state.mentors];
            const mentorIndex = mentorsArray.findIndex(mentor => mentor._id === action.data._id);
            if (mentorIndex !== -1) {
                mentorsArray[mentorIndex] = action.data;
            } else {
                mentorsArray = mentorsArray.concat(action.data);
            }

            return {
                ...state,
                mentor_loading: false,
                mentor: action.data,
                mentor_errors: {},
                mentors: mentorsArray
            };
        }
        case GET_MENTOR_DETAILS_FAILURE: {
            return {
                ...state,
                mentor_loading: false,
                mentor_errors: action.errors,
            };
        }
        // begin::get user redux

        //  begin::reset mentor profile
        case RESET_MENTOR_PROFILE_REQUEST: {
            return {
                ...state,
                mentor_loading: true,
                mentor: {}
            };
        }
        case RESET_MENTOR_PROFILE_SUCCESS: {

            let mentorsArray = [...state.mentors];
            const mentorIndex = mentorsArray.findIndex(mentor => mentor._id === action.data._id);
            if (mentorIndex !== -1) {
                mentorsArray[mentorIndex] = action.data;
            } else {
                mentorsArray = mentorsArray.concat(action.data);
            }

            return {
                ...state,
                mentor_loading: false,
                mentor: action.data,
                mentor_errors: {},
                mentors: mentorsArray
            };
        }
        case RESET_MENTOR_PROFILE_FAILURE: {
            return {
                ...state,
                mentor_loading: false,
                mentor_errors: action.errors,
            };
        }
        // begin::reset mentor profile

        default:
            return state;
    }

}
