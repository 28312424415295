import Cookie from 'universal-cookie'

const cookie = new Cookie();

class cookieServices {

    /**
     * This function retrieves a value from a cookie using a specified key.
     * @param {String} key - The key parameter is a string that represents the name of the cookie that you want
     * to retrieve the value for.
     * @returns {String} The `get` method is returning the value of the cookie with the specified `key`.
     * @author Akshay N
     * @created_at 21/05/2023
     */
    get(key) {
        return cookie.get(key);
    }

    /**
     * This function sets a cookie with a given key, value, and options.
     * @param {String} key - The name of the cookie key that you want to set. It is a string value.
     * @param {String} value - The value to be stored in the cookie. It can be a string, number, boolean, or any
     * other valid JavaScript data type.
     * @param {Object} options - The `options` parameter is an object that can contain various options for
     * setting the cookie. Some common options include:
     * @returns The `set` method is returning the result of calling the `set` method of the `cookie`
     * object with the provided `key`, `value`, and `options` parameters. The return value of the
     * `cookie.set` method is not specified in the code snippet, so it is unclear what is being returned. 
     * @author Akshay N
     * @created_at 21/05/2023
     */
    set(key, value, options) {
        return cookie.set(key, value, options)
    }

    /**
     * This function removes a cookie with a specified key and path.
     * @param {String} key - The key parameter is the name of the cookie that needs to be removed.
     * @returns {Boolean} The `remove` function is returning the result of calling the `remove` method of the
     * `cookie` object with the provided `key` and an options object with a `path` property set to  `'/'`. 
     * @author Akshay N
     * @created_at 21/05/2023
     */
    remove(key) {
        return cookie.remove(key, { path: '/' });
    }
}

const cookieServicesClass = new cookieServices();
export { cookieServicesClass as cookieServices };