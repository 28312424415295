import borders from "../../base/borders";
import typography from "../../base/typography";
import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      fontSize: typography.size.sm,
      borderRadius: borders.borderRadius.xl,
    },

    input: {
      padding: pxToRem(12),
      borderRadius: borders.borderRadius.xl,
    },

    inputSizeSmall: {
      fontSize: typography.size.xs,
    },

  },
  variants: []
};
