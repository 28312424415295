import React from 'react'
import { Card, styled } from '@mui/material'

const FormStyled = styled(({ ...props }) => <Card {...props} />)`
	box-shadow: none;
	background: none;
	border: none;
	& form {
		/* padding: ${(props) => props.theme.spacing(4)} !important;
		border: ${(props) => `${props.theme.borders.borderWidth[1]}  solid ${props.theme.borders.borderColor}`};
		border-radius: ${(props) => props.theme.borders.borderRadius.xxl}; */
	}
`

export { FormStyled }
