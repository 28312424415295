import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { handleInputChange, validateForm } from '../../../../_helpers';
import { PreAuth } from '../../../../_layouts/pre-auth';
import { preAuthRoutes } from '../../../../routes';

import { Grid, Typography } from '@mui/material';

import { AuthAction } from '../../../../redux/actions';
import { SweetAlert, FormInput, SubmitButton, RouteLink } from '../../../../_components';
import { validate } from '.';
import { ArrowForward, ArrowRightAlt } from '@mui/icons-material';

// Initialize form input values to null
const inputs = { email: '' };

function SendResetPasswordLink() {

    /** Initialize plugins and variables */
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /* Destructuring the state from redux store. */
    const { send_pwd_rst_link_loading: isLoading, send_pwd_rst_link_errors: sendPwdRstLinkErrors } = useSelector((state) => state.AuthReducer);
    const sendPasswordResetLink = (params) => dispatch(AuthAction.sendPasswordResetLink(params));

    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        setErrors({ ...sendPwdRstLinkErrors });
    }, [sendPwdRstLinkErrors]);

    /**
     * It takes the name and value of the input, validates it, and sets the errors and data state
     * @param {Object} e form object
     * @param {String} e.name input name
     * @param {String} e.value input value
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        /* Validating the input and setting the errors state. */
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * The function handles the submit event of the form. It prevents the default action of the form,
     * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
     * dispatches the corresponding action
     * @param {Object} e the event object
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        /* Iterating through the data object and validating each key value pair. */
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }

        /* Checking if the form is valid or not. If the form is not valid, it sets the errors state and returns. */
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchSendPasswordResetLinkAction();
    }

    /**
     * function to dispatch send password reset link
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    async function dispatchSendPasswordResetLinkAction() {
        const isLinkSent = await sendPasswordResetLink(data);
        if (isLinkSent.status === 1) {
            SweetAlert.fire({
                title: 'Password Reset Link Send',
                text: "Password reset link sent to your email.Please check!",
                icon: 'success',
                confirmButtonText: 'Ok!',
                reverseButtons: true,
                showCancelButton: false,
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    let redirectPath = preAuthRoutes('sign_in').path;
                    navigate(redirectPath);
                }
            });
        }
    }

    return (
        <PreAuth>
            <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                        <Typography variant="h5">{`${preAuthRoutes('forgot_password').name}`}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormInput
                            tabIndex={1}
                            label='Email'
                            name='email'
                            value={data.email}
                            error={action.isSubmitted && errors.email ? errors.email : ''}
                            onChange={handleChange}
                            autoFocus={true}
                        />
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between" >
                        <SubmitButton label={'Send Link'} loading={isLoading} endIcon={<ArrowForward />} />
                    </Grid>
                    <Grid item xs={12} >
                        <RouteLink to={preAuthRoutes('sign_in').path} label={<Fragment>{preAuthRoutes('sign_in').name} instead <ArrowRightAlt /></Fragment>} />
                    </Grid>
                </Grid>
            </form>
        </PreAuth>
    );
}

export { SendResetPasswordLink };
