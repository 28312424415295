import React from 'react';
import { CardContent, styled } from '@mui/material';

const CardContentStyled = styled(({ ...props }) => (<CardContent {...props} />))`
        padding: ${props => props.theme.spacing(4)};
        &:last-child {
            padding-bottom:${props => props.theme.spacing(4)};
        }
`;

export { CardContentStyled };