import React, { createElement, useState } from 'react';
import { Logout } from '@mui/icons-material';
import { Avatar, ListItemIcon, MenuItem, Tooltip, Typography, Chip, Box, Stack } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { postAuthRoutes, preAuthRoutes } from '../../../../routes';
import { AuthAction } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AccountMenuStyled, AccountMenuPaperStyled } from '.';


function AccountMenu() {

    /** Initialize plugins and variables */
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /* Destructuring the state from redux store. */
    const { user } = useSelector((state) => state.ProfileReducer);
    const logout = () => dispatch(AuthAction.logout());

    /* Initializing the state with the default values. */
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    /**
     * The handleClick function sets the anchor element to the current target of the event.
     * @param {Object} event - The event parameter in the handleClick function is an object that represents the
     * event that triggered the function. It contains information about the event, such as the type of
     * event, the target element, and any additional data related to the event. In this case, the event
     * parameter is used to get the current target
     * @returns {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * The function handleClose sets the value of AnchorEl to null.
     * @param {null}
     * @returns {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * The function logs out the user and redirects them to the sign-in page.
     * @param {null}
     * @returns {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const signOut = () => logout().then(() => {
        /* This code is redirecting the user to the sign-in page after they have logged out. */
        let redirectPath = preAuthRoutes('sign_in').path;
        navigate(redirectPath);
    });

    return (
        <AccountMenuStyled>
            <Tooltip title="Open settings">
                <Chip avatar={<Avatar >{user.short_name}</Avatar>} label={`Hi, ${user.name}`} onClick={handleClick} />
            </Tooltip>
            <AccountMenuPaperStyled anchorEl={anchorEl} open={openMenu} onClose={handleClose} onClick={handleClose} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}  >
                <Box className="profile">
                    <Stack direction="row" justifyContent="left" alignItems="center" spacing={2} >
                        <Avatar variant="square">{user.short_name}</Avatar>
                        <Box>
                            <Typography variant="h6" >{user.name}</Typography>
                            <Typography variant="caption" >{user.role}</Typography>
                        </Box>
                    </Stack>
                </Box>
                <Box className="menu-list">
                    <MenuItem component={Link} to={postAuthRoutes('profile_details').path}>
                        <ListItemIcon>{createElement(postAuthRoutes('profile_details').menuIcon)}</ListItemIcon>
                        {postAuthRoutes('profile_details').name}
                    </MenuItem>
                    <MenuItem onClick={signOut}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Box>
            </AccountMenuPaperStyled>
        </AccountMenuStyled>
    );
}

export { AccountMenu };
