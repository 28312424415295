import { GlobalAction, failure, request, success } from ".";
import { EmailLogServices } from "../services";

export const GET_EMAIL_LOGS_LIST_REQUEST = 'GET_EMAIL_LOGS_LIST_REQUEST';
export const GET_EMAIL_LOGS_LIST_SUCCESS = 'GET_EMAIL_LOGS_LIST_SUCCESS';
export const GET_EMAIL_LOGS_LIST_FAILURE = 'GET_EMAIL_LOGS_LIST_FAILURE';

export const EMAIL_LOG_RESEND_MAIL_REQUEST = 'EMAIL_LOG_RESEND_MAIL_REQUEST';
export const EMAIL_LOG_RESEND_MAIL_SUCCESS = 'EMAIL_LOG_RESEND_MAIL_SUCCESS';
export const EMAIL_LOG_RESEND_MAIL_FAILURE = 'EMAIL_LOG_RESEND_MAIL_FAILURE';

export const EmailLogAction = {

    /**
     * This code block defines an action creator function `getEmailLogsList` that dispatches an asynchronous action to get a list of email logs.
     * It takes in `params` as an argument, which contains the necessary data to fetch the email logs.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at
     */
    getEmailLogsList: (params) => {

        return dispatch => {
            dispatch(request(GET_EMAIL_LOGS_LIST_REQUEST, params));
            return EmailLogServices.getEmailLogsList(params)
                .then(
                    response => { return dispatch(success(GET_EMAIL_LOGS_LIST_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_EMAIL_LOGS_LIST_FAILURE, params, error)); }
                );
        };

    },


    /**
     * This code block defines an action creator function `resendMail` that dispatches an asynchronous action to resend an email.
     * It takes in `params` as an argument, which contains the necessary data to resend the email.
     * @param {Object} params
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    resendMail: (params) => {

        return dispatch => {
            dispatch(request(EMAIL_LOG_RESEND_MAIL_REQUEST, params));
            return EmailLogServices.resendMail(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(EMAIL_LOG_RESEND_MAIL_SUCCESS, params, response)); },
                    error => { return dispatch(failure(EMAIL_LOG_RESEND_MAIL_FAILURE, params, error)); }
                );
        };

    },

};
