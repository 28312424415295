import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserManagementAction } from '../../../../../redux/actions';
import { postAuthRoutes } from "../../../../../routes";
import { ButtonGroup, Chip, Tooltip, IconButton, Stack } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { ChangeCircle, Edit, FilterAlt } from "@mui/icons-material";
import queryString from 'query-string';
import { AbilityCan } from "../../../../../_helpers/permission/AbilityCan";
import { FormSearchInput, GeneralButton, UpdateDataStatusDialog, DataTable } from "../../../../../_components";
import { UsersFilter } from "./users-filter";


function ListUsers() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search: searchParam } = useLocation();
    const searchParams = new URLSearchParams(searchParam);

    /* Destructuring the state from redux store. */
    const { user } = useSelector((state) => state.ProfileReducer);
    const { total_users, users_per_page, users_loading, users } = useSelector((state) => state.UserManagementReducer);
    const getUserList = (params) => dispatch(UserManagementAction.getUserList(params));
    const changeUserStatus = (params) => dispatch(UserManagementAction.changeUserStatus(params));

    /* Initializing the state with the default values. */
    const [showStatusUpdateDialog, setShowStatusUpdateDialog] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });
    const [showUserFilterDialog, setShowUserFilterDialog] = useState(false);
    const [payload, setPayload] = useState({
        page: searchParams.get('page') ?? 1,
        filter: {
            status: searchParams.get('status') ?? '',
        },
        search: searchParams.get('q') ?? '',
        sort: { [searchParams.get('sort_field') ?? 'name']: searchParams.get('sort_order') ?? 'asc', }
    });

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {

        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.page = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;

        queryParam.sort_field = Object.keys(payload.sort)[0];
        queryParam.sort_order = Object.values(payload.sort)[0];

        navigate(`${postAuthRoutes('users').path}?${queryString.stringify(queryParam)}`);
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search, payload.page, payload.sort]);

    /* `const columns` is an array of objects that defines the columns of a data table. Each object
    represents a column and contains properties such as `name`, `selector`, `sortField`, `sortable`,
    `center`, `width`, and `cell`. */
    const columns = [
        { name: "No", center: true, width: '5%', cell: (row, i) => ((users_per_page * (payload.page - 1)) + (i + 1)) },
        { name: "Name", selector: (row, i) => (row.name), sortField: 'name', sortable: true, center: false, width: '25%' },
        { name: "Email", selector: (row, i) => (row.email), sortField: 'email', sortable: true, center: false, width: '30%' },
        { name: "Role", selector: (row, i) => (row.role), sortField: 'role_id', sortable: true, center: false, width: '15%', cell: row => { return row.role_text; } },
        { name: "Created At", selector: (row, i) => (row.created_at), sortField: 'created_at', sortable: true, center: true, width: '15%' },
        { name: "Status", selector: (row, i) => (row.status), sortField: 'status', sortable: true, center: true, width: '10%', cell: row => (<StatusRow row={row} />) },
    ];

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {String} row.status - status values on the row
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const StatusRow = ({ row }) => {

        /**
         * The function handles a click event and navigates to a route for updating a role with
         * the provided row data.
         * @param {Object} row - The parameter "row" is likely an object representing a row of data in a table or
         * list. It is being passed as an argument to the function "handleUpdateClick". The function
         * then uses this row object to navigate to a specific route and pass the row object as state data.
         * @return {null}
         * @author Akshay N
         * @created_at 21/05/2023
         */
        function handleUpdateClick(row) {
            navigate(postAuthRoutes('update_user').path, { state: { user: row } });
        }

        /**
         * The function handles a click event to update the permission status of a row in a modal.
         * @param {Object} row - The `row` parameter is an object that represents a row of data in a table or
         * list. It likely contains various properties that describe the data in that row, such as an
         * ID, a status, and other relevant information. In this function, the `row` parameter is used
         * to pass data
         * @return {null}
         * @author Akshay N
         * @created_at 21/05/2023
         */
        function handleChangeUserStatusClick(row) {
            statusUpdateDialog({ id: row._id, status: row.status, item: '' });
        }

        /* This code is defining a `StatusRow` component that renders a `Chip` component with a label of
        the `status` property of the `row` object passed as a prop. If the `status` property is equal
        to the string "active", the `updateButtonText` variable is set to "Inactivate" and the
        `status` variable is updated to render a `Chip` component with a green color. Otherwise,
        `updateButtonText` is set to "Activate" and the `status` variable renders a `Chip` component
        with a red color. This component is used to display the status of a user in a data table. */
        let status = <Chip label={row.status_text} color={row.status_color} className="action-chip" />;


        /* The above code is defining a JSX element called `statusHover` which contains a `ButtonGroup`
        component with three `IconButton` components inside. Each `IconButton` is wrapped in a
        `Tooltip` component and an `AbilityCan` component which conditionally renders the
        `IconButton` based on the user's role and permissions. The `onClick` event of each
        `IconButton` is set to a corresponding function that handles the click event. */
        let statusChange = (
            <ButtonGroup variant="text" size="small" className='action-row'>
                <AbilityCan I='admin_update_user' passThrough={(user.role === 'developer') ? true : false}>
                    <Tooltip title="Update User">
                        <IconButton onClick={() => handleUpdateClick(row)} color="primary"><Edit /></IconButton>
                    </Tooltip>
                </AbilityCan>
                <AbilityCan I='admin_update_user_status' passThrough={(user.role === 'developer') ? true : false}>
                    <Tooltip title="Change Status">
                        <IconButton onClick={() => handleChangeUserStatusClick(row)} color="primary"><ChangeCircle /></IconButton>
                    </Tooltip>
                </AbilityCan>
            </ButtonGroup>
        );
        return <Fragment>{status}{statusChange} </Fragment>;
    };

    /**
     * The function `getData` calls the `getUserList` function with the `payload` parameter after
     * awaiting its execution.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const getData = async () => {
        await getUserList(payload);
    };

    /**
     * The function handles sorting based on a specified column and sort direction.
     * @param column - The "column" parameter is likely an object that represents a column in a table
     * or grid. It probably contains information such as the column's display name, data type, and
     * sorting options. The "sortField" property of the column object is likely the name of the field
     * in the data that should
     * @param sortDirection - sortDirection is a string parameter that represents the direction of
     * sorting. It can have two possible values: "asc" for ascending order and "desc" for descending
     * order.
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setPayload({ ...payload, sort: { [sortField]: sortDirection } });
    };


    /**
     * The function updates the page value in the payload object.
     * @param page - The `page` parameter is a variable that represents the current page number. It is
     * used in the `handlePage` function to update the `page` property of the `payload` object using
     * the `setPayload` function. The `...payload` syntax is used to spread the existing properties of
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    };

    /**
     * The function toggles the visibility of a user filter modal.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const usersFilterDialog = () => {
        setShowUserFilterDialog(!showUserFilterDialog);
    };

    /**
     * The function applies a filter to the payload data and sets the page to 1.
     * @param filterData - filterData is a parameter that represents the data used to filter the
     * payload. It could be an object containing various filter options such as search keywords, date
     * range, category, etc. The function applies the filterData to the payload by updating the filter
     * property with the new data and resetting the page property to
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    };

    /**
     * The function updates the payload object with a new search text and resets the page number to 1.
     * @param text - The `text` parameter is a string that represents the search query entered by the
     * user. It is used as an argument for the `applySearch` function.
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    };

    /**
     * This function opens a modal to update the status of an item.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const statusUpdateDialog = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateDialog(!showStatusUpdateDialog);
    };

    /**
     * This function updates the status of a user and toggles a modal to show the status update.
     * @param id - The parameter `id` is a unique identifier for a user. It is used as an argument in
     * the `updateStatusAction` function to update the status of the user with the given `id`.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateDialog(!showStatusUpdateDialog);
        await changeUserStatus({ 'user_id': id });
    };


    return (
        <Fragment>
            <UpdateDataStatusDialog show={showStatusUpdateDialog} closeDialog={statusUpdateDialog} data={statusData} updateStatus={updateStatusAction} />
            <DataTable
                title={`Users`}
                loading={users_loading}
                columns={columns}
                data={users}
                total={total_users}
                per_page={users_per_page}
                setPage={page => handlePage(page)}
                handleSort={handleSort}
                actions={
                    <Stack spacing={1} direction="row">
                        <Stack spacing={1} direction="row">
                            <AbilityCan I='admin_user_search' passThrough={(user.role === 'developer') ? true : false}>
                                <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                            </AbilityCan>
                            <AbilityCan I='users_filter' passThrough={(user.role === 'developer') ? true : false}>
                                <GeneralButton size={"small"} label={<FilterAlt />} onClick={() => usersFilterDialog()} />
                            </AbilityCan>
                        </Stack>
                    </Stack>
                }
                subHeaderComponent={<UsersFilter show={showUserFilterDialog} applyFilter={applyFilter} closeDialog={usersFilterDialog} usersFilter={payload.filter} />}
                paginationDefaultPage={payload.page}
            />
        </Fragment>

    );
}
export { ListUsers };