import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    defaultProps: {},
    styleOverrides: {
        root: {
            minWidth: `${pxToRem(24)}`
        }
    },
    variants: []
};
