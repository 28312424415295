import { AuthServices } from "../services";
import { failure, request, success, GlobalAction } from ".";

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const SEND_PWD_RST_LINK_REQUEST = 'SEND_PWD_RST_LINK_REQUEST';
export const SEND_PWD_RST_LINK_SUCCESS = 'SEND_PWD_RST_LINK_SUCCESS';
export const SEND_PWD_RST_LINK_FAILURE = 'SEND_PWD_RST_LINK_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const AuthAction = {

    /**
     * This is an action creator function called `signIn` that dispatches actions to sign in a user.
     * It takes in a `params` object as an argument which contains the email and password of the user.
     * @param {Object} params - The params which are used for registration api.
     * @param {String} params.email - Email of the user
     * @param {String} params.password - Password of the user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    signIn: (params) => {
        return dispatch => {
            dispatch(request(SIGN_IN_REQUEST, params));
            return AuthServices.signIn(params)
                .then(
                    response => { return dispatch(success(SIGN_IN_SUCCESS, params, response)); },
                    error => { return dispatch(failure(SIGN_IN_FAILURE, params, error)); }
                );
        };
    },

    /**
     * This is an action creator function called `register` that dispatches actions to register a new admin user.
     * It takes in a `params` object as an argument which contains the name, email, password, and confirm password of the user.
     * @param {Object} params - The params which are used for registration api.
     * @param {String} params.name - Name of the admin
     * @param {String} params.email - Email of the admin
     * @param {String} params.password - Password entered by the admin
     * @param {String} params.confirm_password - Confirm password entered by the admin
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    register: (params) => {
        return dispatch => {
            dispatch(request(REGISTER_REQUEST, params));
            return AuthServices.register(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(REGISTER_SUCCESS, params, response)); },
                    error => { return dispatch(failure(REGISTER_FAILURE, params, error)); }
                );
        };
    },

    /**
     * `logout` is an action creator function that dispatches an action to sign out the user.
     * It returns a function that takes in `dispatch` as an argument and returns a promise that calls the `signOut` method
     * from the `AuthServices` module. If the promise is resolved, it returns the `response`, otherwise it returns the `error`.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    logout: () => {
        return dispatch => {
            return AuthServices.signOut()
                .then(
                    response => { return response; },
                    error => { return error; }
                );
        };

    },

    /**
     * This is an action creator function called `sendPasswordResetLink` that dispatches actions to send a password reset link to a user.
     * It takes in a `params` object as an argument which contains the email of the user.
     * @param {Object} params - The params which are used for send password reset link api.
     * @param {String} params.email - Email of the admin user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    sendPasswordResetLink: (params) => {
        return dispatch => {
            dispatch(request(SEND_PWD_RST_LINK_REQUEST, params));
            return AuthServices.sendPasswordResetLink(params)
                .then(
                    response => { return dispatch(success(SEND_PWD_RST_LINK_SUCCESS, params, response)); },
                    error => { return dispatch(failure(SEND_PWD_RST_LINK_FAILURE, params, error)); }
                );
        };
    },

    /**
     * This is an action creator function called `resetPassword` that dispatches actions to reset the password of a user.
     * It takes in a `params` object as an argument which contains the email and new password of the user.
     * @param {Object} params - The params which are used for send password reset link api.
     * @param {String} params.email - Email of the  user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    resetPassword: (params) => {
        return dispatch => {
            dispatch(request(RESET_PASSWORD_REQUEST, params));
            return AuthServices.resetPassword(params)
                .then(
                    response => { return dispatch(success(RESET_PASSWORD_SUCCESS, params, response)); },
                    error => { return dispatch(failure(RESET_PASSWORD_FAILURE, params, error)); }
                );
        };
    }
};
