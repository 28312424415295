import {
    CREATE_UPDATE_USER_REQUEST, CREATE_UPDATE_USER_SUCCESS, CREATE_UPDATE_USER_FAILURE,
    CHANGE_USER_STATUS_REQUEST, CHANGE_USER_STATUS_SUCCESS, CHANGE_USER_STATUS_FAILURE,
    GET_USERS_LIST_REQUEST, GET_USERS_LIST_SUCCESS, GET_USERS_LIST_FAILURE,

    ROLES_REQUEST, ROLES_SUCCESS, ROLES_FAILURE,
    CREATE_UPDATE_ROLE_REQUEST, CREATE_UPDATE_ROLE_SUCCESS, CREATE_UPDATE_ROLE_FAILURE,
    CHANGE_ROLE_STATUS_REQUEST, CHANGE_ROLE_STATUS_SUCCESS, CHANGE_ROLE_STATUS_FAILURE,
    ROLES_SELECT_LIST_REQUEST, ROLES_SELECT_LIST_SUCCESS, ROLES_SELECT_LIST_FAILURE,
    ASSIGN_ROLE_PERMISSION_REQUEST, ASSIGN_ROLE_PERMISSION_SUCCESS, ASSIGN_ROLE_PERMISSION_FAILURE,
    GET_ASSIGNED_PERMISSIONS_REQUEST, GET_ASSIGNED_PERMISSIONS_SUCCESS, GET_ASSIGNED_PERMISSIONS_FAILURE,

    CREATE_UPDATE_PERMISSION_REQUEST, CREATE_UPDATE_PERMISSION_SUCCESS, CREATE_UPDATE_PERMISSION_FAILURE,
    CHANGE_PERMISSION_STATUS_REQUEST, CHANGE_PERMISSION_STATUS_SUCCESS, CHANGE_PERMISSION_STATUS_FAILURE,
    PERMISSIONS_SELECT_LIST_REQUEST, PERMISSIONS_SELECT_LIST_SUCCESS, PERMISSIONS_SELECT_LIST_FAILURE,
    GET_PERMISSIONS_REQUEST, GET_PERMISSIONS_SUCCESS, GET_PERMISSIONS_FAILURE,

} from "../actions";

const initialState = {

    create_update_user_loading: false, create_update_user: {}, create_update_user_errors: {},
    change_user_status_loading: false, change_user_status: {}, change_user_status_errors: {},
    users_loading: false, users: [], users_errors: {}, users_page: 1, users_per_page: 10, total_users: 0, total_users_pages: 0,

    create_update_role_loading: false, create_update_role: {}, create_update_role_errors: {},
    change_role_status_loading: false, change_role_status_errors: {},
    roles_loading: false, roles: [], roles_errors: {}, roles_page: 1, roles_per_page: 10, total_roles: 0, total_roles_pages: 0,
    roles_select_list_loading: false, roles_select_list: [], roles_select_list_errors: {},

    assign_permission_loading: false, assign_permission_errors: {},
    assigned_permissions_loading: false, assigned_permissions: {}, all_permissions: [], assigned_permissions_errors: {},

    create_update_permission_loading: false, create_update_permission: {}, create_update_permission_errors: {},
    change_permission_status_loading: false, change_permission_status_errors: {},
    permissions_loading: false, permissions: [], permissions_errors: [], permissions_page: 0, permissions_per_page: 10, permissions_total: 0, permissions_total_pages: 0,
    permissions_select_list_loading: false, permissions_select_list: [], permissions_select_list_errors: {},
};

export function UserManagementReducer(state = initialState, action) {
    switch (action.type) {

        //  begin::add user redux
        case CREATE_UPDATE_USER_REQUEST: {
            return {
                ...state,
                create_update_user_loading: true,
                create_update_user_errors: {}
            };
        }
        case CREATE_UPDATE_USER_SUCCESS: {

            let usersArray = [...state.users]; //making a new array
            const userIndex = usersArray.findIndex(user => user._id === action.data._id);

            if (userIndex !== -1) {
                usersArray[userIndex].status = action.data.status;
            }

            return {
                ...state,
                create_update_user_loading: false,
                create_update_user: action.data,
                users: usersArray,
                create_update_user_errors: {}
            };

        }
        case CREATE_UPDATE_USER_FAILURE: {
            return {
                ...state,
                create_update_user_loading: false,
                create_update_user_errors: action.errors,
            };
        }
        // begin::add user redux

        //  begin::get user redux
        case CHANGE_USER_STATUS_REQUEST: {
            return {
                ...state,
                change_user_status_loading: true,
                change_user_status: {}
            };
        }
        case CHANGE_USER_STATUS_SUCCESS: {

            let usersArray = [...state.users];
            const userIndex = usersArray.findIndex(user => user._id === action.data._id);
            if (userIndex !== -1) {
                usersArray[userIndex] = action.data;
            } else {
                usersArray = usersArray.concat(action.data);
            }

            return {
                ...state,
                change_user_status_loading: false,
                users: usersArray,
                change_user_status_errors: {}
            };
        }
        case CHANGE_USER_STATUS_FAILURE: {
            return {
                ...state,
                change_user_status_loading: false,
                change_user_status_errors: action.errors,
            };
        }
        // begin::get user redux

        //  begin::Get users list redux
        case GET_USERS_LIST_REQUEST: {
            return {
                ...state,
                users_loading: true,
                users_errors: {},
            };
        }
        case GET_USERS_LIST_SUCCESS: {

            let newArray = [...state.users]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.users.forEach((payload) => {
                const userIndex = newArray.findIndex(users => users._id === payload._id);
                if (userIndex !== -1) {
                    newArray[userIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                users_loading: false,
                users: newArray,
                users_errors: {},
                users_page: action.data.page,
                users_per_page: action.data.per_page,
                total_users: action.data.total,
                total_users_pages: action.data.total_pages
            };
        }
        case GET_USERS_LIST_FAILURE: {
            return {
                ...state,
                users_loading: false,
                users_errors: action.errors,
            };
        }
        // begin::Get users list redux

        /** begin::Create admin Role redux */
        case CREATE_UPDATE_ROLE_REQUEST: {
            return {
                ...state,
                create_update_role_loading: true,
                create_update_role_errors: {}
            };
        }
        case CREATE_UPDATE_ROLE_SUCCESS: {

            let rolesArray = [...state.roles]; //making a new array
            const roleIndex = rolesArray.findIndex(role => role._id === action.data._id);

            if (roleIndex !== -1) {
                rolesArray[roleIndex].status = action.data.status;
            }

            return {
                ...state,
                create_update_role_loading: false,
                roles: rolesArray,
                create_update_role: action.data,
                create_update_role_errors: {}
            };

        }
        case CREATE_UPDATE_ROLE_FAILURE: {
            return {
                ...state,
                create_update_role_loading: false,
                create_update_role_errors: action.errors
            };
        }

        /** end::Create role redux */

        /** begin::Change role status redux */
        case CHANGE_ROLE_STATUS_REQUEST: {
            return {
                ...state,
                change_role_status_loading: true,
                change_role_status_errors: {}
            };
        }
        case CHANGE_ROLE_STATUS_SUCCESS: {

            let roleArray = [...state.roles]; //making a new array
            if (action.data.page === 1) {
                roleArray = [];
            }

            const roleIndex = roleArray.findIndex(role => role._id === action.data._id);
            if (roleIndex !== -1) {
                roleArray[roleIndex] = action.data;
            } else {
                roleArray = roleArray.concat(action.data);
            }

            return {
                ...state,
                change_role_status_loading: false,
                roles: roleArray,
                change_role_status_errors: {}
            };
        }
        case CHANGE_ROLE_STATUS_FAILURE: {
            return {
                ...state,
                change_role_status_loading: false,
                change_role_status_errors: action.errors
            };
        }
        /** end::Change role status redux */

        /** begin::Role list redux */
        case ROLES_REQUEST: {
            return {
                ...state,
                roles_loading: true,
                roles_errors: {}
            };
        }
        case ROLES_SUCCESS: {

            let newArray = [...state.roles]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.roles.forEach((payload) => {
                const roleIndex = newArray.findIndex(roles => roles._id === payload._id);
                if (roleIndex !== -1) {
                    newArray[roleIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                roles_loading: false,
                roles: newArray,
                roles_errors: {},
                roles_page: action.data.page,
                roles_per_page: action.data.per_page,
                total_roles: action.data.total,
                total_roles_pages: action.data.total_pages
            };

        }
        case ROLES_FAILURE: {
            return {
                ...state,
                roles_loading: false,
                roles_errors: action.errors,
                roles: [],
            };
        }
        /** end::Role list redux */

        /** begin::Roles select list redux */
        case ROLES_SELECT_LIST_REQUEST: {
            return {
                ...state,
                roles_select_list_loading: true,
                roles_select_list_errors: {}
            };
        }
        case ROLES_SELECT_LIST_SUCCESS: {

            return {
                ...state,
                roles_select_list_loading: false,
                roles_select_list_errors: {},
                roles_select_list: action.data

            };

        }
        case ROLES_SELECT_LIST_FAILURE: {
            return {
                ...state,
                roles_select_list_loading: false,
                roles_select_list_errors: action.errors,
                roles_select_list: []
            };
        }
        /** end::Roles select list redux */

        /** begin::Assign permissions redux */
        case ASSIGN_ROLE_PERMISSION_REQUEST: {
            return {
                ...state,
                assign_permission_loading: true,
                assigned_permissions_errors: {}
            };
        }
        case ASSIGN_ROLE_PERMISSION_SUCCESS: {

            return {
                ...state,
                roles_select_list_loading: false,
                assigned_permissions_errors: {},

            };

        }
        case ASSIGN_ROLE_PERMISSION_FAILURE: {
            return {
                ...state,
                roles_select_list_loading: false,
                assigned_permissions_errors: action.errors,
            };
        }
        /** end::Assign permissions redux */

        /** begin::Get assigned permissions redux */
        case GET_ASSIGNED_PERMISSIONS_REQUEST: {
            return {
                ...state,
                assigned_permissions_loading: true,
                assigned_permissions_errors: {}
            };
        }
        case GET_ASSIGNED_PERMISSIONS_SUCCESS: {

            return {
                ...state,
                assigned_permissions_loading: false,
                assigned_permissions: action.data.roleArray,
                all_permissions: action.data.permissionArray,
                assigned_permissions_errors: action.data.roleArray

            };

        }
        case GET_ASSIGNED_PERMISSIONS_FAILURE: {
            return {
                ...state,
                assigned_permissions_loading: false,
                assigned_permissions_errors: action.errors,
                assigned_permissions: {},
                all_permissions: []
            };
        }
        /** end::Get assigned permissions redux */


        /** begin::Create Permission redux */
        case CREATE_UPDATE_PERMISSION_REQUEST: {
            return {
                ...state,
                create_update_permission_loading: true,
                create_update_permission_errors: {}
            };
        }
        case CREATE_UPDATE_PERMISSION_SUCCESS: {

            let permissionsArray = [...state.permissions]; //making a new array
            const permissionIndex = permissionsArray.findIndex(permission => permission._id === action.data._id);

            if (permissionIndex !== -1) {
                permissionsArray[permissionIndex].status = action.data.status;
            }

            return {
                ...state,
                create_update_permission_loading: false,
                permissions: permissionsArray,
                create_update_permission: action.data,
                create_update_permission_errors: {}
            };

        }
        case CREATE_UPDATE_PERMISSION_FAILURE: {
            return {
                ...state,
                create_update_permission_loading: false,
                create_update_permission_errors: action.errors
            };
        }
        /** end::Create Permission redux */

        /** begin::Change Permission status redux */
        case CHANGE_PERMISSION_STATUS_REQUEST: {
            return {
                ...state,
                change_permission_status_loading: true,
                change_permission_status_errors: {}
            };
        }
        case CHANGE_PERMISSION_STATUS_SUCCESS: {
            let newArray = [...state.permissions];
            const permissionIndex = newArray.findIndex(permissions => permissions._id === action.data._id);

            if (permissionIndex !== -1) {
                newArray[permissionIndex].status = action.data.status;
            }

            return {
                ...state,
                change_permission_status_loading: false,
                change_permission_status_errors: {},
                users: newArray
            };
        }
        case CHANGE_PERMISSION_STATUS_FAILURE: {
            return {
                ...state,
                change_permission_status_loading: false,
                change_permission_status_errors: action.errors
            };
        }
        /** end::Change Permission status redux */

        /** begin::Permissions list redux */
        case GET_PERMISSIONS_REQUEST: {
            return {
                ...state,
                permissions_loading: true,
                permissions_errors: {}
            };
        }
        case GET_PERMISSIONS_SUCCESS: {
            // let newArray = [...state.permissions]; //making a new array
            // if (action.data.page === 1) {
            let newArray = [];
            // }
            action.data.permissions.forEach((payload) => {
                const permissionIndex = newArray.findIndex(permissions => permissions._id === payload._id);
                if (permissionIndex !== -1) {
                    newArray[permissionIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                permissions_loading: false,
                permissions: newArray,
                permissions_errors: {},
                permissions_page: action.data.page,
                permissions_per_page: action.data.per_page,
                permissions_total: action.data.total,
                permissions_total_pages: action.data.total_pages
            };
        }
        case GET_PERMISSIONS_FAILURE: {
            return {
                ...state,
                permissions_loading: false,
                permissions: [],
                permissions_errors: action.errors
            };
        }
        /** end::Users list redux */

        /** begin::Permissions select list redux */
        case PERMISSIONS_SELECT_LIST_REQUEST: {
            return {
                ...state,
                permissions_select_list_loading: true,
                permissions_select_list_errors: {}
            };
        }
        case PERMISSIONS_SELECT_LIST_SUCCESS: {

            return {
                ...state,
                permissions_select_list_loading: false,
                permissions_select_list_errors: {},
                permissions_select_list: action.data

            };

        }
        case PERMISSIONS_SELECT_LIST_FAILURE: {
            return {
                ...state,
                permissions_select_list_loading: false,
                permissions_select_list_errors: action.errors,
                permissions_select_list: []
            };
        }
        /** end::Permissions select list redux */


        default:
            return state;
    }
}
