import React from 'react';
import MuiCarousel from 'react-material-ui-carousel';

const Carousel = ({ ...props }) => {

	return (
		<MuiCarousel navButtonsAlwaysVisible={true} indicators={false} autoPlay={false} animation={'slide'} duration={700} width="100%" {...props}>
			{props.children}
		</MuiCarousel>
	);
};

export { Carousel };
