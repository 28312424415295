import React, { Fragment } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { useSelector } from "react-redux";
import { postAuthRoutes } from '../../../../routes';
import { ListRoles } from '.';
import { AbilityCan } from '../../../../_helpers/permission/AbilityCan';
import { PageHeader, GeneralButton, RouteLink } from '../../../../_components';

function Roles() {

    /* Destructuring the state from redux store. */
    const { user } = useSelector((state) => state.ProfileReducer);

    return (
        <Fragment>
            <Box>
                <PageHeader
                    title={postAuthRoutes('role').name}
                    action={
                        <Stack spacing={1} direction="row">
                            <AbilityCan I='add_role' passThrough={(user.role === 'developer') ? true : false}>
                                <RouteLink to={postAuthRoutes('create_role').path} label={<GeneralButton label={postAuthRoutes('create_role').name} />} />
                            </AbilityCan>
                        </Stack>
                    }
                />
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} >
                    <ListRoles />
                </Grid>
            </Grid>
        </Fragment>
    );
}

export { Roles };