import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import queryString from 'query-string';
import { postAuthRoutes } from '../../../../../routes';
import { AbilityCan } from '../../../../../_helpers/permission/AbilityCan';
import { UserManagementAction } from '../../../../../redux/actions';

import { Edit, FilterAlt, ChangeCircle } from '@mui/icons-material';
import { Chip, ButtonGroup, Tooltip, IconButton, Stack } from '@mui/material';
import { FormSearchInput, GeneralButton, UpdateDataStatusDialog, DataTable } from '../../../../../_components';
import { PermissionFilter } from '.';

function ListPermissions() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search: searchParam } = useLocation();
    const searchParams = new URLSearchParams(searchParam);

    /* Destructuring the state from redux store. */
    const { user } = useSelector((state) => state.ProfileReducer);
    const { permissions_per_page, permissions_total, permissions_loading, permissions } = useSelector((state) => state.UserManagementReducer);
    const getPermissionsList = (params) => dispatch(UserManagementAction.getPermissionsList(params));
    const changePermissionStatus = (params) => dispatch(UserManagementAction.changePermissionStatus(params));

    /* Initializing the state with the default values. */
    const [showPermissionFilterDialog, setShowPermissionFilterDialog] = useState(false);
    const [showStatusUpdateDialog, setShowStatusUpdateDialog] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });

    /* The below code is defining a state variable `payload` using the `useState` hook in  The
    initial state of `payload` is an object with four properties: `page`, `filter`, `search`, and `sort`. */
    const [payload, setPayload] = useState({
        page: searchParams.get('page') ?? 1,
        filter: {
            status: searchParams.get('status') ?? '',
            parent: searchParams.get('parent') ?? ''
        },
        search: searchParams.get('q') ?? '',
        sort: { [searchParams.get('sort_field') ?? 'name']: searchParams.get('sort_order') ?? 'asc', }
    });

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {

        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.page = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;

        queryParam.sort_field = Object.keys(payload.sort)[0];
        queryParam.sort_order = Object.values(payload.sort)[0];

        navigate(`${postAuthRoutes('permissions').path}?${queryString.stringify(queryParam)}`);
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search, payload.page, payload.sort]);


    /* The above code is defining an array of objects called `columns`. Each object represents a column
    in a table and contains properties such as the column name, the data selector function, the sort
    field, the width, and the cell renderer function. The `columns` array is likely used to
    configure a table component in a React application. */
    const columns = [
        { name: '#', selector: (row, i) => (row._id), sortField: '_id', center: true, width: '6%', cell: (row, i) => ((permissions_per_page * (payload.page - 1)) + (i + 1)) },
        { name: 'Permission', selector: (row, i) => (row.name), sortField: 'name', sortable: true, width: '35%' },
        { name: 'Parent Permission', selector: (row, i) => (row.parent_details.name), sortField: 'parent_details.name', sortable: true, width: '35%', style: { whiteSpace: `unset` } },
        { name: 'Created Date', selector: (row, i) => (row.created_at), sortField: 'created_at', center: true, sortable: true, width: '14%' },
        { name: 'Status', selector: (row, i) => (row.status), sortField: 'status', center: true, sortable: true, width: '10%', cell: row => (<StatusRow row={row} />) },
    ];

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {String} row.status - status values on the row
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const StatusRow = ({ row }) => {

        /**
         * The function handles a click event and navigates to a route for updating a permission with
         * the provided row data.
         * @param {Object} row - The parameter "row" is likely an object representing a row of data in a table or
         * list. It is being passed as an argument to the function "handleUpdateClick". The function
         * then uses this row object to navigate to a specific route and pass the row object as state data.
         * @return {null}
         * @author Akshay N
         * @created_at 21/05/2023
         */
        function handleUpdateClick(row) {
            navigate(postAuthRoutes('update_permission').path, { state: { permission: row } });
        }

        /**
         * The function handles a click event to update the permission status of a row in a modal.
         * @param {Object} row - The `row` parameter is an object that represents a row of data in a table or
         * list. It likely contains various properties that describe the data in that row, such as an
         * ID, a status, and other relevant information. In this function, the `row` parameter is used
         * to pass data
         * @return {null}
         * @author Akshay N
         * @created_at 21/05/2023
         */
        function handleChangePermissionStatusClick(row) {
            setTimeout(() => {
                statusUpdateDialog({ id: row._id, status: row.status, item: '' });
            }, 100);
        }

        let status = <Chip label={row.status} />;
        if (row.status === 'active')
            status = <Chip label={row.status[0].toUpperCase() + row.status.substring(1)} color="success" className="action-chip" />;
        else if (row.status === 'inactive')
            status = <Chip label={row.status[0].toUpperCase() + row.status.substring(1)} color="error" className="action-chip" />;

        let statusHover = (

            <ButtonGroup variant="text" size="small" className='action-row'>
                <AbilityCan I='update_permission' passThrough={(user.role === 'developer') ? true : false}>
                    <Tooltip title="Update Permission">
                        <IconButton onClick={() => handleUpdateClick(row)} size="small"><Edit /></IconButton>
                    </Tooltip>
                </AbilityCan>
                <AbilityCan I='permissions_change_status' passThrough={(user.role === 'developer') ? true : false}>
                    <Tooltip title="Change Status">
                        <IconButton onClick={() => handleChangePermissionStatusClick(row)} size="small"><ChangeCircle /></IconButton>
                    </Tooltip>
                </AbilityCan>
            </ButtonGroup>

        );
        return <Fragment>{status}{statusHover}</Fragment>;
    };

    /**
     * The function `getData` calls the `getPermissionsList` function with the `payload` parameter
     * after awaiting its completion.
     * @param {Object} payload - The params which are used for the api
     * @param {String} payload.page - Page number to load
     * @param {String} payload.filter - Filter data
     * @param {String} payload.search - Search key
     * @param {String} payload.sort - sort key
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const getData = async () => {
        await getPermissionsList(payload);
    };

    /**
     * The function handles sorting based on a specified column and sort direction.
     * @param {Object} column - The "column" parameter is likely an object that represents a column in a table
     * or grid. It probably contains information such as the column's display name, data type, and
     * sorting options. The "sortField" property of the column object is likely the field or property
     * in the data that the column
     * @param {String} sortDirection - sortDirection is a string that represents the direction of the sorting.
     * It can be either "asc" for ascending order or "desc" for descending order.
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setPayload({ ...payload, sort: { [sortField]: sortDirection } });
    };

    /**
     * The function updates the page value in the payload object.
     * @param {Number} page - The `page` parameter is a variable that represents the page number that the user
     * wants to navigate to. It is used in the `handlePage` function to update the `page` property of
     * the `payload` object using the `setPayload` function.
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    };

    /**
     * The function updates the payload object with a new search text and resets the page number to 1.
     * @param {String} text - The `text` parameter is a string that represents the search query entered by the
     * user. It is used as an argument for the `applySearch` function.
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    };

    /**
     * This function toggles the visibility of a permission filter modal.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const permissionFilterDialog = () => {
        setShowPermissionFilterDialog(!showPermissionFilterDialog);
    };

    /**
     * The function applies a filter to the payload data and sets the page to 1.
     * @param {Object} filterData - filterData is a parameter that represents the data used to filter the
     * payload. It could be an object containing various filter options such as search keywords, date
     * range, category, etc. The function applies the filterData to the payload by updating the filter
     * property with the new data and resetting the page property to
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    };

    /**
     * This function opens a modal to update the status of an item.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const statusUpdateDialog = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateDialog(!showStatusUpdateDialog);
    };

    /**
     * This function updates the status of a permission and toggles a modal for updating the status.
     * @param {String} id - The parameter `id` is a unique identifier for a permission. It is used as an argument
     * for the `updateStatusAction` function to update the status of a permission.
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateDialog(!showStatusUpdateDialog);
        await changePermissionStatus({ 'permission_id': id });
    };

    return (
        <Fragment>
            <UpdateDataStatusDialog show={showStatusUpdateDialog} closeDialog={statusUpdateDialog} data={statusData} updateStatus={updateStatusAction} />
            <DataTable
                title="Permissions"
                loading={permissions_loading}
                columns={columns}
                data={permissions}
                total={permissions_total}
                per_page={permissions_per_page}
                setPage={page => handlePage(page)}
                handleSort={handleSort}
                actions={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I='permissions_search' passThrough={(user.role === 'developer') ? true : false}>
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        </AbilityCan>
                        <AbilityCan I='permissions_filter' passThrough={(user.role === 'developer') ? true : false}>
                            <GeneralButton size={"small"} label={<FilterAlt />} onClick={() => permissionFilterDialog()} />
                        </AbilityCan>
                    </Stack>
                }
                subHeaderComponent={<PermissionFilter show={showPermissionFilterDialog} closeDialog={permissionFilterDialog} applyFilter={applyFilter} permissionFilter={payload.filter} />}
                paginationDefaultPage={payload.page}
            />
        </Fragment>
    );
}

export { ListPermissions };
