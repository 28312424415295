module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {String} name Input name
     * @param {String} value Input value
     * @param {Object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 21/05/2023
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const errors = {};
        switch (name) {
            case 'name': {
                errors.name = '';
                if (value === '')
                    errors.name = 'Enter name';
                break;
            } case 'email': {
                errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid';
                if (value === '')
                    errors.email = 'Enter an email address';
                break;
            } case 'password': {
                errors.password = '';
                if (!data.user_id) {
                    errors.password = value.length < 6 ? 'Password must be 6 characters long' : '';
                    if (value === '')
                        errors.password = 'Enter password';
                }
                break;
            } case 'role_id': {
                errors.role_id = '';
                if (value === '')
                    errors.role_id = 'Select role';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
};