import chip from "../../../_root/components/chip/chip";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...chip,

    styleOverrides: {
        root: {
            ...chip.root,
            // color: colors.link.main
        }
    },

    colorPrimary: {
        backgroundColor: colors.primary.main,
    },
};
