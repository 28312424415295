/**
 * The function checks if a form is valid by iterating through its errors and returning a boolean
 * value.
 * @param {Object} errors - The `errors` parameter is an object that contains error messages for form
 * validation. The keys of the object correspond to the form fields, and the values are the error
 * messages for each field.
 * @returns {Boolean} The function `validateForm` is returning a boolean value (`true` or `false`) depending on
 * whether all the values in the `errors` object are empty strings or not. If all values are empty
 * strings, `valid` will remain `true` and the function will return `true`. If any value is not an
 * empty string, `valid` will be set to `false` and the function will return `false`.
 * @author Akshay N
 * @created_at 21/05/2023
 */
const validateForm = (errors) => {
    for (let value of Object.values(errors)) {
        if (value !== '') {
            return false;
        }
    }
    return true;
}

export { validateForm }


