import { styled } from '@mui/material';
import PropTypes from 'prop-types';


const LogoBox = styled(({ ...props }) => (
	<svg viewBox="0 0 962 315" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path fillRule="evenodd" clipRule="evenodd" d="M801.155 77.0929C780.952 86.6304 765.748 101.571 755.095 121.585C743.95 141.938 741.534 163.955 740.168 186.165C739.901 190.496 740.212 194.845 740.522 199.19C740.671 201.27 740.819 203.349 740.904 205.425C741.461 219.015 743.799 232.146 747.951 245.04C753.651 262.737 763.919 277.089 778.539 288.604C798.67 304.462 821.589 312.35 846.971 313.529C857.686 314.027 868.39 313.538 878.953 311.535C891.005 309.249 902.472 305.336 913.124 299.093C921.438 294.221 928.452 287.943 935.231 281.124C946.499 269.789 954.109 256.632 957.858 241.156C958.611 238.046 958.35 236.416 954.283 236.466C936.286 236.686 918.284 236.701 900.288 236.454C896.251 236.399 893.711 237.126 892.039 241.124C890.624 244.509 888.514 247.664 885.835 250.328C875.771 260.339 863.5 263.445 849.636 262.646C836.287 261.877 824.953 256.806 816.269 246.985C807.677 237.269 804.603 224.993 803.06 212.323C802.585 208.42 803.927 207.455 807.839 207.466C833.793 207.542 859.748 207.554 885.703 207.565C909.412 207.576 933.121 207.587 956.83 207.646C960.317 207.655 961.404 206.864 961.167 203.312C960.83 198.275 960.817 193.236 960.803 188.189C960.789 182.817 960.775 177.436 960.371 172.042C959.45 159.78 957.282 147.926 953.385 136.412C948.63 122.364 941.004 110.003 931.09 98.7896C922.169 88.6997 911.476 81.7089 899.424 76.2787C890.845 72.4131 881.757 70.4737 872.634 68.5512C865.375 67.0216 857.869 66.9913 850.653 67.001C844.649 67.009 838.314 67.485 832.135 68.3147C821.293 69.7706 810.934 72.4763 801.155 77.0929ZM890.519 165.561C896.922 165.561 897.478 164.644 895.123 157.483C893.059 142.432 885.562 131.227 872.884 124.057C862.348 118.098 850.627 117.051 838.866 119.736C831.03 121.526 823.932 125.259 818.672 131.555C811.738 139.853 807.927 149.728 805.318 160.091C804.456 163.516 804.566 165.739 809.556 165.689C829.596 165.489 849.637 165.516 869.679 165.543C876.626 165.552 883.572 165.561 890.519 165.561Z" fill="white" className='light'/>
		<path d="M588.349 167.637C595.743 193.302 602.947 218.599 610.03 243.931C610.468 245.499 610.612 246.802 612.566 246.976C618.095 227.529 623.592 208.106 629.141 188.698C637.782 158.477 646.457 128.266 655.125 98.0535C655.877 95.4306 656.666 92.818 657.463 90.2081C659.965 82.0178 659.969 82.0193 668.574 82.0193C687.896 82.0193 707.218 82.0193 727.008 82.0193C722.167 95.483 717.395 108.692 712.669 121.917C700.116 157.047 687.882 192.294 674.949 227.283C664.679 255.067 654.662 282.938 644.756 310.851C643.99 313.009 643.236 314.135 640.503 314.111C620.513 313.94 600.521 314.01 580.53 314.034C578.682 314.036 577.626 313.987 576.784 311.587C567.038 283.791 557.013 256.092 547.094 228.356C536.04 197.45 525.176 166.475 513.921 135.643C507.826 118.945 501.677 102.272 495.797 85.4964C494.515 81.8393 495.803 82.0035 498.364 82.0064C519.189 82.0295 540.013 82.0333 560.837 82C563.193 81.9963 564.456 82.3166 565.256 85.2237C572.021 109.813 579.169 134.297 586.009 158.866C586.779 161.632 588.447 164.225 588.349 167.637Z" fill="white" className='light' />
		<path d="M359.079 172.966C354.748 187.664 350.332 201.914 346.147 216.232C342.006 230.401 338.157 244.655 334.073 258.841C329.147 275.952 323.979 292.994 319.238 310.155C318.346 313.383 317.085 314.228 313.958 314.195C299.961 314.048 285.96 314.056 271.962 314.182C268.358 314.214 267.452 313.632 268.782 309.745C281.027 273.958 292.966 238.067 305.136 202.254C314.677 174.18 324.423 146.175 334.073 118.138C337.304 108.75 340.598 99.3822 343.683 89.946C344.312 88.0216 344.699 87.0179 347.138 87.0484C360.801 87.2196 374.472 87.2992 388.131 87.0017C391.196 86.9349 391.227 88.8536 391.761 90.4282C401.588 119.422 411.336 148.443 421.089 177.462C429.77 203.292 438.387 229.145 447.129 254.955C453.299 273.173 459.589 291.35 465.926 309.511C467.181 313.106 466.448 314.266 462.457 314.211C447.96 314.008 433.459 314.04 418.962 314.205C415.928 314.239 414.811 313.23 414.011 310.363C406.465 283.317 398.779 256.309 391.068 229.309C386.132 212.028 381.081 194.78 376.115 177.508C375.923 176.841 375.888 176.236 374.097 176.253C374.097 181.523 373.717 186.883 374.21 192.16C374.655 196.923 371.814 196.106 369.049 196.155C366.16 196.205 363.587 196.677 363.979 192.064C364.471 186.273 364.386 180.389 363.996 174.582C363.734 170.693 365.024 169.551 368.66 170.09C370.285 170.331 371.976 170.131 373.891 170.131C371.79 162.665 369.753 155.428 367.316 146.768C363.319 155.818 361.378 164.242 359.079 172.966Z" fill="white" className='light' />
		<path d="M371.008 306.131C360.097 306.131 360.097 306.131 360.097 295.546C360.097 287.715 360.203 279.881 360.041 272.053C359.983 269.267 360.31 268.028 363.687 268.026C376.097 268.015 376.097 267.855 376.097 280.227C376.097 287.393 375.82 294.573 376.207 301.717C376.421 305.681 375.027 306.748 371.008 306.131Z" fill="white" className='light' />
		<path d="M369.099 246.131C365.007 246.805 363.789 245.531 363.995 241.697C364.359 234.929 364.337 228.116 364.018 221.343C363.783 216.349 367.238 218.349 369.349 218.215C371.463 218.08 374.415 216.855 374.187 221.34C373.842 228.111 373.837 234.925 374.199 241.693C374.403 245.519 373.198 246.808 369.099 246.131Z" fill="white" className='light' />
		<path fillRule="evenodd" clipRule="evenodd" d="M0.0988204 247.671C0.0980433 263.148 0.0972657 278.636 0.0972657 294.137C0.0972682 295.855 0.116588 297.528 0.135539 299.168C0.179088 302.939 0.220683 306.541 0.0213761 310.129C-0.160913 313.41 0.844321 314.244 4.1042 314.216C23.2645 314.056 42.4281 314.023 61.5874 314.235C65.3186 314.277 66.2114 313.378 66.1936 309.635C66.1108 292.277 66.1035 274.918 66.0963 257.56C66.0891 240.594 66.082 223.629 66.0044 206.663C65.9875 202.971 66.8192 202.083 70.5802 202.026C80.0515 201.882 89.5243 201.992 98.9974 202.103C116.107 202.303 133.218 202.502 150.321 201.201C168.095 199.848 185.025 195.972 200.706 187.2C213.769 179.893 223.949 169.956 230.476 156.342C237.912 140.833 239.966 124.139 240.967 107.347C241.796 93.4215 240.414 79.4952 237.039 65.9213C232.188 46.4062 222.182 30.3217 205.291 18.6977C186.764 5.94822 165.871 0.642049 143.993 0.33457C115.546 -0.0652225 87.0936 -0.0166009 58.6405 0.0320216C40.9375 0.0622737 23.2343 0.0925262 5.53218 0.0147762C0.851798 -0.00578476 -0.00576891 1.34258 2.90916e-05 5.71276C0.106924 86.2668 0.10288 166.821 0.0988204 247.671ZM173.766 89.9731C173.108 81.8549 170.176 73.901 163.316 67.8522C153.744 59.7816 142.594 56.5606 130.85 56.2734C118.703 55.9763 106.548 56.0199 94.3924 56.0636C86.396 56.0922 78.3992 56.1209 70.4042 56.0526C66.9851 56.0234 65.9409 56.7446 66.002 60.3386C66.1527 69.1995 66.1325 78.0631 66.1123 86.927C66.101 91.8856 66.0897 96.8442 66.1083 101.802C66.1474 112.214 66.2284 122.625 66.3095 133.036C66.3449 137.577 66.3802 142.118 66.4121 146.659C75.715 147.332 85.0261 147.236 94.3372 147.141C99.9588 147.084 105.58 147.026 111.2 147.138C121.078 147.334 130.961 146.953 140.642 145.052C153.621 142.504 164.373 136.709 170.228 123.765C175.193 112.789 174.683 101.29 173.766 89.9731Z" fill="white" className='light' />
	</svg>
))`
        & .light {
            fill: ${props => (props.fill) ? (props.fill) : (props.theme.palette.mode === 'dark') ? (props.theme.palette.light.main) : (props.theme.palette.dark.main)};
        }
` ;

const Logo = ({ height, ...props }) => {
	return (
		<LogoBox height={height} {...props} />
	);
};
Logo.defaultProps = {
	height: '25'
};

Logo.propTypes = {
	props: PropTypes.object,
	height: PropTypes.string
};

export { Logo };
