import React from 'react'
import { CardContent, styled } from '@mui/material'

const FormContentStyled = styled(({ ...props }) => <CardContent {...props} />)`
	padding: 0;
	/* padding: ${(props) => props.theme.spacing(4)} !important; */
	/* border: ${(props) => `${props.theme.borders.borderWidth[1]}  solid ${props.theme.borders.borderColor}`}; */
	/* border-radius: ${(props) => props.theme.borders.borderRadius.xxl}; */
`

export { FormContentStyled }
