import React from 'react'
import { CardActions, styled } from '@mui/material'

const FormActionStyled = styled(({ ...props }) => <CardActions {...props} />)`
	padding: ${(props) => `${props.theme.spacing(2)} 0 0`} !important;
	& .MuiStack-root {
		width: 100%;
	}
`

export { FormActionStyled }
