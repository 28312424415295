import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { ProfileAction } from '../../../redux/actions';
import { Container, Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import { getPostElementNameByPath, postAuthRoutes } from '../../../routes';
import { AccountMenu, HeaderStyled } from '.';
import { useContext } from 'react';
import { AppNameContext } from '../../../_contexts';
import { ShortLogo } from '../../../_components/logo';
import { RouteLink } from '../../../_components';

function Header({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const location = useLocation();
    const APP_NAME = useContext(AppNameContext);

    /* Destructuring the state from redux store. */
    const { isDrawerOpen: open, themeLayout } = useSelector((state) => state.GlobalReducer);
    const getUserDetails = () => dispatch(ProfileAction.getUserDetails());
    const getUserPermission = () => dispatch(ProfileAction.getUserPermission());

    /* Initializing the state with the default values. */
    const [pageTitle, setPageTitle] = useState('Loading');

    useEffect(() => {
        setPageTitle(getPostElementNameByPath(location.pathname));
    }, [location]);

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        getUserDetails();
        getUserPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${pageTitle}`}</title>
            </Helmet>
            {themeLayout === 'vertical' ? (
                <HeaderStyled position="sticky" color='primary'>
                    <Toolbar className={open ? 'drawer-open' : ''}>
                        <RouteLink to={postAuthRoutes('mentors').path} label={<ShortLogo className='logo' fill="#fff" />} />
                        {/* <RouteLink to={postAuthRoutes('home').path} label={<ShortLogo className='logo' fill="#fff" />} /> */}
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            {/* <ThemeMode /> */}
                            {/* <Notifications /> */}
                            <AccountMenu />
                        </Box>
                    </Toolbar>
                </HeaderStyled>
            ) : (
                <HeaderStyled position='static' color='primary' >
                    <Container>
                        <Toolbar disableGutters>
                            <RouteLink to={postAuthRoutes('mentors').path} label={<ShortLogo className='logo' />} fill="#fff" />
                            {/* <RouteLink to={postAuthRoutes('home').path} label={<ShortLogo className='logo' />} fill="#fff" /> */}
                            <Box sx={{ flexGrow: 1 }} />
                            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                {/* <ThemeMode /> */}
                                {/* <Notifications /> */}
                                <AccountMenu />
                            </Box>
                        </Toolbar>
                    </Container>
                </HeaderStyled>
            )}

        </Fragment>
    );
}

export { Header };
