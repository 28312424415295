import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getPortals, handleInputChange, validateForm } from "../../../../../_helpers";
import { validate } from ".";
import { Grid, } from "@mui/material";
import { UserManagementAction } from "../../../../../redux/actions";
import { postAuthRoutes } from "../../../../../routes";
import { FormInput, FormSelect, CancelButton, SubmitButton, FormAction, Animate, Form, FormHeader, FormContent } from "../../../../../_components";

// Initialize form input values to null
const inputs = { permission_id: '', name: '', parent_id: '', portal: '' };

function CreateUpdatePermission() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { permission } = location.state ?? {};
    const portals = getPortals();

    /* Destructuring the state from redux store. */
    const { create_update_permission_loading, create_update_permission_errors, permissions_select_list } = useSelector((state) => state.UserManagementReducer);
    const addPermission = (params) => dispatch(UserManagementAction.addPermission(params));
    const updatePermission = (params) => dispatch(UserManagementAction.updatePermission(params));
    const permissionSelectList = (params) => dispatch(UserManagementAction.permissionSelectList(params));

    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });
    const [isSetPermissionLoading, setPermissionLoading] = useState(true);
    const [response, setResponse] = useState({});

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        permissionSelectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        setErrors({ ...create_update_permission_errors });
    }, [create_update_permission_errors]);

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        if (permission && permission._id) {
            setData({ permission_id: permission._id, name: permission.name, parent_id: permission.parent_id, portal: permission.portal });
            setPermissionLoading(false);
        } else {
            setPermissionLoading(false);
        }
    }, [permission]);

    /**
     * It takes the name and value of the input, validates it, and sets the errors and data state
     * @param {Object} e form object
     * @param {String} e.name input name
     * @param {String} e.value input value
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        /* Validating the input and setting the errors state. */
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * The function handles the submit event of the form. It prevents the default action of the form,
     * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
     * dispatches the corresponding action
     * @param {Object} e the event object
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        /* Iterating through the data object and validating each key value pair. */
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }

        /* Checking if the form is valid or not. If the form is not valid, it sets the errors state and returns. */
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchUpdatePermissionAction(data);
    }

    /**
     * This function dispatches an action to update or add a permission and redirects to the
     * permissions page upon success.
     * @param {Object} formData - The `formData` parameter is an object that contains data to be used for
     * updating or adding a permission. It is likely to include properties such as `name`,
     * `description`, and `permissions` that describe the permission being updated or added.
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    async function dispatchUpdatePermissionAction(formData) {
        const isCreated = permission && permission._id ? await updatePermission(formData) : await addPermission(formData);
        setResponse(isCreated);
    }

    /**
     * The function cancels a permission and redirects to a specified path.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    function cancelPermission() {
        let redirectPath = `${postAuthRoutes('permissions').path}`;
        navigate(redirectPath);
    }

    useEffect(() => {
        if (response && response.status === 1) {
            let redirectPath = `${postAuthRoutes('permissions').path}`;
            navigate(redirectPath);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);


    return (
        <Animate>
            <Grid container spacing={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                <Grid item xs={4}>
                    <Form>
                        <FormHeader title={permission && permission._id ? postAuthRoutes('update_permission').name : postAuthRoutes('create_permission').name} />
                        <form onSubmit={handleSubmit} noValidate>
                            <FormContent >
                                <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center">
                                    <Grid item xs={12}>
                                        <FormSelect
                                            tabIndex={1}
                                            label={`Portal`}
                                            name={`portal`}
                                            placeholder={`Select portal`}
                                            onChange={handleChange}
                                            data={portals}
                                            error={action.isSubmitted && errors.portal ? errors.portal : ''}
                                            className="col-md-6"
                                            value={data.portal}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormInput
                                            tabIndex={2}
                                            label='Permission'
                                            name='name'
                                            value={data.name}
                                            error={action.isSubmitted && errors.name ? errors.name : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    {!isSetPermissionLoading && !create_update_permission_loading &&
                                        <Grid item xs={12}>
                                            <FormSelect
                                                tabIndex={3}
                                                label={`Parent Permission`}
                                                name={`parent_id`}
                                                placeholder={`Select parent permission`}
                                                onChange={handleChange}
                                                data={permissions_select_list}
                                                error={action.isSubmitted && errors.parent_id ? errors.parent_id : ''}
                                                className="col-md-6"
                                                value={data.parent_id}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </FormContent>
                            <FormAction>
                                <CancelButton onClick={cancelPermission} tabIndex={5} />
                                <SubmitButton label={permission && permission._id ? 'Update' : 'Create'} loading={create_update_permission_loading} tabIndex={4} />
                            </FormAction>
                        </form>
                    </Form>
                </Grid>
            </Grid>
        </Animate>
    );
}

export { CreateUpdatePermission };
