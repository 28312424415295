import {
    USER_REQUEST, USER_SUCCESS, USER_FAILURE,
    USER_PERMISSION_REQUEST, USER_PERMISSION_SUCCESS, USER_PERMISSION_FAILURE,
    USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAILURE,
    CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE,
    GET_ACTIVITY_LOG_REQUEST, GET_ACTIVITY_LOG_SUCCESS, GET_ACTIVITY_LOG_FAILURE,
    GET_NOTIFICATIONS_REQUEST, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAILURE,
    MARK_ALL_NOTIFICATIONS_READ_REQUEST, MARK_ALL_NOTIFICATIONS_READ_SUCCESS, MARK_ALL_NOTIFICATIONS_READ_FAILURE,
    MARK_NOTIFICATION_READ_REQUEST, MARK_NOTIFICATION_READ_SUCCESS, MARK_NOTIFICATION_READ_FAILURE,
} from "../actions";

/**
 * This is defining the initial state of the Redux store for the application.
 * It contains several properties with their initial values:
 * */
const initialState = {
    user_loading: true, user: {}, user_errors: [],
    user_permission_loading: true, user_permission: [], user_permission_errors: {},
    change_password_loading: false, change_password: {}, change_password_errors: [],
    activity_logs_loading: false, activity_logs: [], activity_logs_errors: {}, activity_logs_page: 0, activity_logs_per_page: 0, activity_logs_total: 0, activity_logs_total_pages: 0,
    notifications_loading: false, notifications: [], notifications_errors: {}, pending_notifications: 0, notifications_page: 0, notifications_per_page: 0, total_notifications: 0, total_notifications_pages: 0,
    mark_all_notifications_read_loading: false, mark_all_notifications_read_errors: {},
    mark_notification_read_loading: false, mark_notification_read_errors: {}
};

export function ProfileReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::User details redux */
        case USER_REQUEST: {
            return {
                ...state,
                user_loading: true,
                user_errors: {}
            };
        }
        case USER_SUCCESS: {
            return {
                ...state,
                user_loading: false,
                user: action.data,
                user_errors: {}
            };
        }
        case USER_FAILURE: {
            return {
                ...state,
                user_loading: false,
                user_errors: action.errors
            };
        }
        /** end::User details redux */

        /** begin::User permissions redux */
        case USER_PERMISSION_REQUEST: {
            return {
                ...state,
                user_permission_loading: true,
                user_permission_errors: {}
            };
        }
        case USER_PERMISSION_SUCCESS: {
            return {
                ...state,
                user_permission_loading: false,
                user_permission: action.data.permissions,
                user_permission_errors: {}
            };
        }
        case USER_PERMISSION_FAILURE: {
            return {
                ...state,
                user_permission_loading: false,
                user_permission_errors: action.errors,
                user_permission: []
            };
        }
        /** end::User permissions redux */

        /** begin::User update redux */
        case USER_UPDATE_REQUEST: {
            return {
                ...state,
                user_loading: true,
                user_errors: {}
            };
        }
        case USER_UPDATE_SUCCESS: {
            return {
                ...state,
                user_loading: false,
                user: action.data,
                user_errors: {}
            };
        }
        case USER_UPDATE_FAILURE: {
            return {
                ...state,
                user_loading: false,
                user_errors: action.errors
            };
        }
        /** end::User update redux */

        /** begin::Activity log redux */
        case GET_ACTIVITY_LOG_REQUEST: {
            return {
                ...state,
                activity_logs_loading: true,
                activity_logs_errors: {}
            };
        }
        case GET_ACTIVITY_LOG_SUCCESS: {

            let newArray = [...state.activity_logs]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.activities.forEach((payload) => {
                const loginActivityIndex = newArray.findIndex(activityLog => activityLog._id === payload._id);
                if (loginActivityIndex !== -1) {
                    newArray[loginActivityIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                activity_logs_loading: false,
                activity_logs: newArray,
                activity_logs_errors: {},
                activity_logs_page: action.data.page,
                activity_logs_per_page: action.data.per_page,
                activity_logs_total: action.data.total,
                activity_logs_total_pages: action.data.total_pages
            };

        }
        case GET_ACTIVITY_LOG_FAILURE: {
            return {
                ...state,
                activity_logs_loading: false,
                activity_logs_errors: action.errors,
                activity_logs: []
            };
        }
        /** end::Activity log redux */

        /** begin::Notifications redux */
        case GET_NOTIFICATIONS_REQUEST: {
            return {
                ...state,
                notifications_loading: true,
                notifications_errors: {}
            };
        }
        case GET_NOTIFICATIONS_SUCCESS: {

            let newArray = [...state.notifications]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.notifications.forEach((payload) => {
                const notificationIndex = newArray.findIndex(notification => notification._id === payload._id);
                if (notificationIndex !== -1) {
                    newArray[notificationIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                notifications_loading: false,
                notifications_errors: {},
                notifications: newArray,
                pending_notifications: (action.data && action.data.notification_status) ? action.data.notification_status.pending : 0,
                notifications_page: action.data.page,
                notifications_per_page: action.data.per_page,
                total_notifications: action.data.total,
                total_notifications_pages: action.data.total_pages
            };

        }
        case GET_NOTIFICATIONS_FAILURE: {
            return {
                ...state,
                notifications_loading: false,
                notifications_errors: action.errors,
                notifications: []
            };
        }
        /** end::Notifications redux */

        /** begin::Mark all notifications read redux */
        case MARK_ALL_NOTIFICATIONS_READ_REQUEST: {
            return {
                ...state,
                mark_all_notifications_read_loading: true,
                mark_all_notifications_read_errors: {}
            };
        }
        case MARK_ALL_NOTIFICATIONS_READ_SUCCESS: {
            let newArray = [...state.notifications]; //making a new array
            let pendingNotifications = 0; //making a new array

            action.data.forEach((payload) => {
                const notificationIndex = newArray.findIndex(notification => notification._id === payload._id);
                if (payload.status === 'pending') {
                    pendingNotifications = pendingNotifications + 1;
                }
                if (notificationIndex !== -1) {
                    newArray[notificationIndex].status = payload.status;
                }
            });

            return {
                ...state,
                mark_all_notifications_read_loading: false,
                mark_all_notifications_read_errors: {},
                pending_notifications: pendingNotifications
            };
        }
        case MARK_ALL_NOTIFICATIONS_READ_FAILURE: {
            return {
                ...state,
                mark_all_notifications_read_loading: false,
                mark_all_notifications_read_errors: action.errors
            };
        }
        /** end::Mark all notifications read redux */

        /** begin::Mark notification read redux */
        case MARK_NOTIFICATION_READ_REQUEST: {
            return {
                ...state,
                mark_notification_read_loading: true,
                mark_notification_read_errors: {}
            };
        }
        case MARK_NOTIFICATION_READ_SUCCESS: {

            let notificationArray = [...state.notifications]; //making a new array
            let pendingNotifications = state.pending_notifications; //making a new array

            const notificationId = notificationArray.findIndex(notification => notification._id === action.data._id);
            if (notificationId !== -1) {
                notificationArray[notificationId].status = action.data.status;
                pendingNotifications = pendingNotifications - 1;
            }

            return {
                ...state,
                mark_notification_read_loading: false,
                mark_notification_read_errors: {},
                notifications: notificationArray,
                pending_notifications: pendingNotifications
            };
        }
        case MARK_NOTIFICATION_READ_FAILURE: {
            return {
                ...state,
                mark_notification_read_loading: false,
                mark_notification_read_errors: action.errors
            };
        }
        /** end::Mark notification read redux */

        /** begin::Change password redux */
        case CHANGE_PASSWORD_REQUEST: {
            return {
                ...state,
                change_password_loading: true,
                change_password_errors: {}
            };
        }
        case CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                change_password_loading: false,
                change_password: action.data,
                change_password_errors: {}
            };
        }
        case CHANGE_PASSWORD_FAILURE: {
            return {
                ...state,
                change_password_loading: false,
                change_password_errors: action.errors
            };
        }
        /** end::Change passwor redux */

        default:
            return state;
    }
}
