import React from 'react';
import { AppBar, styled } from '@mui/material';

const HeaderStyled = styled(({ ...props }) => (<AppBar {...props} />))`
    zIndex: 1200;
    & .MuiSvgIcon-root{
        color: ${props => props.theme.palette.common.white} !important;
    }
    & .MuiAvatar-root {
        background-color: ${props => props.theme.palette.common.white} !important;
        & .MuiSvgIcon-root{
        color: ${props => props.theme.palette.primary.main} !important;
        }
    }
    & .logo{
        margin-left:  ${props => `${props.theme.functions.pxToRem(70)}`};
    }
`;


export { HeaderStyled };
