import React from 'react';
import { styled } from '@mui/material';
import { Card } from '../../../../_components';

const NotificationsStyled = styled(({ ...props }) => (<Card {...props} />))`

    padding:0 !important;

    & .MuiCardHeader-root {
        padding: ${props => `${props.theme.spacing(5)}`};
        padding-bottom: 0;
    }

    & .MuiCardContent-root  {
        padding:0;
        padding-top: ${props => `${props.theme.spacing(4)}`};

        & .MuiBox-root {
            padding:${props => `${props.theme.spacing(1.25)} ${props.theme.spacing(3.125)}`};
        }

        &:last-child {
            padding-bottom:0 !important;
        }

    }
    & .activity-log-grid {
        &:nth-child(odd) {
            background-color: ${props => `${props.theme.functions.rgba(props.theme.palette.primary.light, 0.2)}`};
        }
        &:nth-child(even) {
            border-bottom: ${props => `1px solid ${props.theme.functions.rgba(props.theme.palette.primary.light, 0.2)}`};
        }
    }


`;

export { NotificationsStyled };