import { postAuthRoutes, preAuthRoutes, PrivateRoute } from ".";
import { NotFound } from "../_components/layout";

/**
 * The function `getBrowserRouter` creates an array of objects representing all routes in a web
 * application, including parent and child routes, by calling `postAuthRoutes` and `preAuthRoutes`.
 * @param {null}
 * @returns {Array} The function `getBrowserRouter` is returning an array of objects that represent the routes
 * of a React application. The array includes objects for both pre-authentication routes and
 * post-authentication routes, as well as a catch-all route for any paths that do not match any of the
 * defined routes. The objects in the array include properties such as `path`, `element`, and `children`
 * @author Akshay N
 * @created_at 21/05/2023
 */
function getBrowserRouter() {

    // Calling the function `postAuthRoutes` and storing the return value in `postSignInRoutes`.
    const postSignInRoutes = postAuthRoutes();

    let postRoutes = [];

    // Iterating over the object.
    for (const key in postSignInRoutes) {

        // Checking if the object has the property.
        if (postSignInRoutes.hasOwnProperty(key)) {

            // Checking if the route has a parent route.
            if (postSignInRoutes[key].parentRoute) {

                // Finding the index of the parent route in the array of objects.
                const parentRoute = postSignInRoutes[key].parentRoute;
                var postRouteIndex = postRoutes.findIndex(function (route) {
                    return route.slug === parentRoute;
                });

                // Checking if the parent route has any children.
                // If not, it is creating an empty array  and pushing the child route into it.
                if (!postRoutes[postRouteIndex]['children'])
                    postRoutes[postRouteIndex]['children'] = [];

                postRoutes[postRouteIndex]['children'].push({ ...postSignInRoutes[key], slug: key, path: postSignInRoutes[key].path.split("/").slice(2).join("/") });

            } else
                postRoutes.push({ ...postSignInRoutes[key], slug: key });
        }
    }

    // Calling the function `preAuthRoutes` and storing the return value in `preSignInRoutes`.
    const preSignInRoutes = preAuthRoutes();
    let preRoutes = [];

    for (const key in preSignInRoutes) {

        // Iterating over the object.
        if (preSignInRoutes.hasOwnProperty(key)) {

            // Checking if the route has a parent route.
            if (preSignInRoutes[key].parentRoute) {

                // Finding the index of the parent route in the array of objects.
                const parentRoute = preSignInRoutes[key].parentRoute;
                var preRouteIndex = preRoutes.findIndex(function (route) {
                    return route.slug === parentRoute;
                });

                // Checking if the parent route has any children.
                // If not, it is creating an empty array and pushing the child route into it.
                if (!preRoutes[preRouteIndex]['children'])
                    preRoutes[preRouteIndex]['children'] = [];

                preRoutes[preRouteIndex]['children'].push({ ...preSignInRoutes[key], slug: key, path: preSignInRoutes[key].path.split("/").slice(2).join("/") });

            } else
                preRoutes.push({ ...preSignInRoutes[key], slug: key });
        }
    }

    // Creating an array of objects.
    const allRoutes = ([
        ...preRoutes,
        {
            path: "/",
            element: <PrivateRoute />,
            children: postRoutes,
        },
        {
            path: '/*',
            element: <NotFound />
        }
    ]);

    return allRoutes;
}

export { getBrowserRouter };
