import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserManagementAction } from "../../../../../../redux/actions";
import { getPortals, handleInputChange } from "../../../../../../_helpers";
import { Chip, Grid, Stack } from "@mui/material";
import { FormSelect, Dialog, DialogContent, DialogActionCloseBtn, DialogActionSaveBtn, DialogTitle } from "../../../../../../_components";

// Initialize form input values to null
const inputs = { status: '', parent: '' };

// Declare filter status select options
const filterStatus = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
];

function PermissionFilter({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const { show, closeDialog, applyFilter, permissionFilter } = props;

    /* Destructuring the state from redux store. */
    const portals = getPortals();
    const { permissions_select_list } = useSelector((state) => state.UserManagementReducer);
    const permissionSelectList = (params) => dispatch(UserManagementAction.permissionSelectList(params));

    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs });
    const [filteredData, setFilteredData] = useState({});

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        permissionSelectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {

        setData({ status: permissionFilter.status, parent: permissionFilter.parent });

        let filtered = {};
        console.log(permissionFilter);
        Object.keys(permissionFilter).forEach(function (item, i) {
            if (permissionFilter[item]) {
                filtered[item] = permissionFilter[item];
                if (item === 'parent') {
                    let status = permissions_select_list.filter(function (obj) { return obj.value === permissionFilter[item]; });
                    if (status[0])
                        filtered[item] = status[0].label;
                }
                return filtered[item];
            }
        });
        setFilteredData({ ...filtered });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissionFilter]);

    /**
     * This function resets data and closes a modal.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const onClose = () => {
        setData({ status: '', parent: '' });
        closeDialog(false);
    };

    /**
     * The function clears filters and closes a modal.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const onClear = () => {
        setData({ status: '', parent: '' });
        applyFilter({ status: '', parent: '' });
        closeDialog(false);
    };

    /**
     * This function updates the state of a data object with the new value of an input field.
     * @param {Object} e - The parameter "e" is an event object that is passed to the function when an event
     * occurs, such as a change in the input field. It contains information about the event, such as
     * the target element and the value of the input.
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        setData({ ...data });
    }

    /**
     * This function handles form submission, applies a filter to data, and closes a modal.
     * @param {Object} e - The parameter "e" is an event object that is passed to the function when the form is
     * submitted. It is used to prevent the default behavior of the form submission, which is to reload
     * the page.
     * @return {json}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    function handleSubmit(e) {
        e.preventDefault();
        applyFilter(data);
        closeDialog(false);
    }

    /**
     * This function clears a specific item from a data object and applies the updated filter.
     * @param {String} item - The `item` parameter is a string representing the key of a property in the `data`
     * object that needs to be cleared. The function creates a new object `clearItem` by spreading the
     * `data` object, then sets the value of the property with the key `item` to an empty
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const clearFilterItem = (item) => {
        const clearItem = { ...data };
        clearItem[item] = '';
        setData({ ...clearItem });
        applyFilter({ ...clearItem });
    };

    return (
        <Fragment>
            {
                Object.keys(filteredData).length > 0 &&
                (
                    <Grid container mb={2} >
                        <Grid item xs={12} >
                            <Stack direction="row" spacing={1}>
                                {
                                    Object.keys(filteredData).map((item, i) => {
                                        if (item === 'status') {
                                            const status = filterStatus.find(function (status) { return status.value === filteredData[item]; });
                                            return <Chip key={i} label={status.label} onDelete={() => clearFilterItem(item)} />;
                                        }
                                        else if (item === 'portal') {
                                            const portal = portals.find(function (portal) { return portal.value === filteredData[item]; });
                                            return <Chip key={i} label={portal.label} onDelete={() => clearFilterItem(item)} />;
                                        } else {
                                            return <Chip key={i} label={filteredData[item]} onDelete={() => clearFilterItem(item)} />;
                                        }
                                    })
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                )
            }
            <Dialog isDialogOpen={show} onClose={onClose} maxWidth='xs'>
                <form onSubmit={handleSubmit} noValidate >
                    <DialogTitle id="userFilter" onClose={onClose} title='Filter' />
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <FormSelect
                                    tabIndex={1}
                                    label={`Status`}
                                    name={`status`}
                                    placeholder={`Select status`}
                                    onChange={handleChange}
                                    data={filterStatus}
                                    className="col-md-6"
                                    value={data.status}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <FormSelect
                                    tabIndex={2}
                                    label={`Parent Permission`}
                                    name={`parent`}
                                    placeholder={`Select role`}
                                    onChange={handleChange}
                                    data={permissions_select_list}
                                    className="col-md-6"
                                    value={data.parent}
                                />
                            </Grid>
                            <Grid item xs={8} >
                                <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                                    <DialogActionSaveBtn tabIndex={4} text='Filter' />
                                    <DialogActionCloseBtn tabIndex={3} onClose={onClear} text='Clear' />
                                </Stack>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </form>
            </Dialog>
        </Fragment>
    );
}

export { PermissionFilter };