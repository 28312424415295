

import React, { useEffect, useState } from 'react';
import { Snackbar as MuiSnackbar } from '@mui/material';
import Slide from '@mui/material/Slide';
import { useDispatch } from 'react-redux';
import { GlobalAction } from '../../redux/actions';

function Snackbar({ message }) {

    const [isOpen, setIsOpen] = useState(message ? true : false);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsOpen(message ? true : false)
        return () => {
            setTimeout(() => {
                dispatch(GlobalAction.showToastMessage(null))
            }, 3000);
        };
        //eslint-disable-next-line
    }, [message])

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <MuiSnackbar open={isOpen} onClose={handleClose} TransitionComponent={Slide} message={message} key={Slide.name} />
    );
}


export { Snackbar };
