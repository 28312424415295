import React, { Fragment, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { UserManagementAction } from '../../../../../redux/actions';
import { Chip, ButtonGroup, Tooltip, IconButton, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { postAuthRoutes } from '../../../../../routes';
import { Edit, FilterAlt, Key } from '@mui/icons-material';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { AbilityCan } from '../../../../../_helpers/permission/AbilityCan';
import { FormSearchInput, GeneralButton, UpdateDataStatusDialog, DataTable } from '../../../../../_components';
import { RoleFilter } from './role-filter';

function ListRoles() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search: searchParam } = useLocation();
    const searchParams = new URLSearchParams(searchParam);

    /* Destructuring the state from redux store. */
    const { user } = useSelector((state) => state.ProfileReducer);
    const { roles_per_page, total_roles, roles_loading, roles } = useSelector((state) => state.UserManagementReducer);
    const listRoles = (params) => dispatch(UserManagementAction.listRoles(params));
    const changeRoleStatus = (params) => dispatch(UserManagementAction.changeRoleStatus(params));

    /* Initializing the state with the default values. */
    const [showRoleFilterDialog, setShowRoleFilterDialog] = useState(false);
    const [payload, setPayload] = useState({
        page: searchParams.get('page') ?? 1,
        filter: {
            status: searchParams.get('status') ?? '',
            portal: searchParams.get('portal') ?? ''
        },
        search: searchParams.get('q') ?? '',
        sort: { [searchParams.get('sort_field') ?? 'name']: searchParams.get('sort_order') ?? 'asc', }
    });
    const [showStatusUpdateDialog, setShowStatusUpdateDialog] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '' });

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.page = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;

        queryParam.sort_field = Object.keys(payload.sort)[0];
        queryParam.sort_order = Object.values(payload.sort)[0];

        navigate(`${postAuthRoutes('role').path}?${queryString.stringify(queryParam)}`);
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search, payload.page, payload.sort]);

    /* `const columns` is an array of objects that defines the columns of the data table. Each object
    represents a column and contains properties such as `name` (the name of the column), `selector`
    (a function that selects the data for that column from each row), `sortField` (the field to use
    for sorting), `center` (whether to center the column), `width` (the width of the column), and
    `cell` (a function that returns the cell content for that column). The `cell` property in the
    last object is a custom cell that renders a component called `StatusRow` for the "Status"
    column. */
    const columns = [
        { name: '#', selector: (row, i) => (row._id), sortField: '_id', center: true, width: '5%', cell: (row, i) => ((roles_per_page * (payload.page - 1)) + (i + 1)) },
        { name: 'Portal', selector: (row, i) => row.portal, sortField: 'portal', center: true, sortable: true, width: '25%', cell: (row) => { return row.portal_text; } },
        { name: 'Name', selector: (row, i) => (row.name), sortField: 'name', sortable: true, width: '35%' },
        { name: 'Created Date', selector: (row, i) => (row.created_at), sortField: 'created_at', center: true, sortable: true, width: '15%' },
        { name: 'Status', selector: (row, i) => (row.status), sortField: 'status', center: true, sortable: true, width: '20%', cell: row => (<StatusRow row={row} />) },
    ];

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {String} row.status - status values on the row
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const StatusRow = ({ row }) => {

        const [statusData] = useState({ id: row._id, status: row.status });

        /**
         * The function handles a click event and navigates to a route for updating a permission with
         * the provided row data.
         * @param {Object} row - The parameter "row" is likely an object representing a row of data in a table or
         * list. It is being passed as an argument to the function "handleUpdateClick". The function
         * then uses this row object to navigate to a specific route and pass the row object as state data.
         * @return {null}
         * @author Akshay N
         * @created_at 21/05/2023
         */
        function handleUpdateClick(row) {
            navigate(postAuthRoutes('update_role').path, { state: { role: row } });
        }

        /**
         * The function handles a click event and calls the statusUpdateDialog function after a 100ms delay.
         * @param {null}
         * @return {null}
         * @author Akshay N
         * @created_at 21/05/2023
         */
        function handleChangeRoleStatusClick() {
            setTimeout(() => {
                statusUpdateDialog(statusData);
            }, 100);
        }

        /**
         * The function handles a click event on a button to navigate to a page for assigning
         * permissions to a specific row.
         * @param row - The parameter `row` is likely an object representing a row of data in a table
         * or list. It is being passed as an argument to the `handleAssignPermissionClick` function.
         * The function then uses the `_id` property of the `row` object to construct a URL and navigate to the address
         * @return {null}
         * @author Akshay N
         * @created_at 21/05/2023
         */
        function handleAssignPermissionClick(row) {
            navigate(`${postAuthRoutes('assign_permission').path}?rId=${row._id}`);
        }

        /* This code is creating a `Chip` component with a label based on the `status` property of the
        `row` object. If the `status` is "active", the `Chip` will have a green color and the label
        will be capitalized. If the `status` is "inactive", the `Chip` will have a red color and the
        label will be capitalized. The `Chip` component is used in the `StatusRow` function to
        display the status of each row in the data table. */
        let status = <Chip label={row.status_text} color={row.status_color} className="action-chip" />;

        /* The above code is defining a JSX element called `statusHover` which contains a `ButtonGroup`
        component with three `IconButton` components inside. Each `IconButton` is wrapped in a
        `Tooltip` component and an `AbilityCan` component which conditionally renders the
        `IconButton` based on the user's role and permissions. The `onClick` event of each
        `IconButton` is set to a corresponding function that handles the click event. */
        let statusHover = (
            <ButtonGroup variant="text" size="small" className='action-row'>
                <AbilityCan I='update_role' passThrough={(user.role === 'developer') ? true : false}>
                    <Tooltip title="Update Role">
                        <IconButton onClick={() => handleUpdateClick(row)} size="small"><Edit /></IconButton>
                    </Tooltip>
                </AbilityCan>
                <AbilityCan I='assign_permission' passThrough={(user.role === 'developer') ? true : false}>
                    <Tooltip title="Assign Permission">
                        <IconButton onClick={() => handleAssignPermissionClick(row)} size="small"><Key /></IconButton>
                    </Tooltip>
                </AbilityCan>
                <AbilityCan I='roles_change_status' passThrough={(user.role === 'developer') ? true : false}>
                    <Tooltip title="Change Status">
                        <IconButton onClick={() => handleChangeRoleStatusClick(row)} size="small"><ChangeCircleIcon /></IconButton>
                    </Tooltip>
                </AbilityCan>
            </ButtonGroup>
        );

        return <Fragment>{status}{statusHover}</Fragment>;

    };

    /**
     * The function `getData` calls the `listRoles` function with the `payload` parameter after awaiting
     * its execution.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const getData = async () => { await listRoles(payload); };

    /**
     * The function handles sorting based on a specified column and sort direction.
     * @param column - The "column" parameter is likely an object that represents a column in a table
     * or grid. It probably contains information such as the column's display name, data type, and
     * sorting options. The "sortField" property of the column object is likely the name of the field
     * in the data that should
     * @param sortDirection - sortDirection is a string that represents the direction of the sorting.
     * It can be either "asc" for ascending order or "desc" for descending order.
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setPayload({ ...payload, sort: { [sortField]: sortDirection } });
    };

    /**
     * The function updates the page value in the payload object.
     * @param page - The `page` parameter is a variable that represents the current page number. It is
     * used in the `handlePage` function to update the `page` property of the `payload` object using
     * the `setPayload` function. The `...payload` syntax is used to spread the existing properties of
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handlePage = (page) => { setPayload({ ...payload, page: page }); };

    /**
     * This function toggles the visibility of a role filter modal.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const roleFilterDialog = () => { setShowRoleFilterDialog(!showRoleFilterDialog); };

    /**
     * The function applies a filter to the payload data and sets the page to 1.
     * @param filterData - `filterData` is a variable that contains data to be used as a filter for a
     * certain operation. It is passed as a parameter to the `applyFilter` function. The function then
     * updates the `payload` object by spreading its current properties and adding a new `filter`
     * property with the value
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const applyFilter = (filterData) => { setPayload({ ...payload, page: 1, filter: filterData }); };

    /**
     * The function updates the payload object with a new search text and resets the page number to 1.
     * @param text - The "text" parameter is a string that represents the search query entered by the
     * user. It is used as an argument for the "applySearch" function.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const applySearch = (text) => { setPayload({ ...payload, page: 1, search: text }); };

    /**
     * This function updates the status of a modal based on the provided ID and status.
     * @param {String} id - The parameter `id` is a unique identifier for a role. It is used as an argument in
     * the `updateStatusAction` function to update the status of a role.
     * @param {String} status - The parameter `status` is current status for a role. It is used as an argument in
     * the `updateStatusAction` function to update the status of a role.
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const statusUpdateDialog = ({ id, status }) => {
        setStatusData({ id: id, status: status });
        setShowStatusUpdateDialog(!showStatusUpdateDialog);
    };

    /**
     * This function updates the status of a role and toggles a modal for updating the status.
     * @param id - The parameter `id` is a unique identifier for a role. It is used as an argument in
     * the `updateStatusAction` function to update the status of a role.
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateDialog(!showStatusUpdateDialog);
        await changeRoleStatus({ 'role_id': id });
    };

    return (
        <Fragment>
            <UpdateDataStatusDialog show={showStatusUpdateDialog} closeDialog={statusUpdateDialog} data={statusData} updateStatus={updateStatusAction} />
            <DataTable
                title="Roles"
                loading={roles_loading}
                columns={columns}
                data={roles}
                total={total_roles}
                per_page={roles_per_page}
                setPage={page => handlePage(page)}
                handleSort={handleSort}
                actions={
                    <Stack spacing={1} direction="row">
                        <Stack spacing={1} direction="row">
                            <AbilityCan I='roles_search' passThrough={(user.role === 'developer') ? true : false}>
                                <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                            </AbilityCan>
                            <AbilityCan I='roles_filter' passThrough={(user.role === 'developer') ? true : false}>
                                <GeneralButton label={<FilterAlt />} onClick={() => roleFilterDialog()} />
                            </AbilityCan>
                        </Stack>
                    </Stack>
                }
                subHeaderComponent={<RoleFilter show={showRoleFilterDialog} closeDialog={roleFilterDialog} applyFilter={applyFilter} roleFilter={payload.filter} />}
                paginationDefaultPage={payload.page}
            />
        </Fragment>
    );

}

export { ListRoles };