import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserManagementAction } from '../../../../../redux/actions';
import { postAuthRoutes } from '../../../../../routes';
import { handleInputChange, validateForm } from '../../../../../_helpers';
import { validate } from '.';
import { CancelButton, SubmitButton, FormInput, FormPassword, FormSelect, Animate, FormHeader, FormContent, FormAction, Form } from '../../../../../_components';

// Initialize form input values to null
const inputs = { user_id: '', name: '', email: '', password: '', role_id: '', role: '' };

function CreateUpdateUser() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = location.state ?? {};

    /* Destructuring the state from redux store. */
    const { create_update_user_loading, create_update_user_errors } = useSelector((state) => state.UserManagementReducer);
    const { roles_select_list_loading, roles_select_list } = useSelector((state) => state.UserManagementReducer);
    const addUser = (params) => dispatch(UserManagementAction.addUser(params));
    const updateUser = (params) => dispatch(UserManagementAction.updateUser(params));
    const getRoleSelectList = (params) => dispatch(UserManagementAction.getRoleSelectList(params));

    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });
    const [isSetUserLoading, setUserLoading] = useState(true);

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        getRoleSelectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        setErrors({ ...create_update_user_errors });
    }, [create_update_user_errors]);

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        if (user && user._id) {
            setData({ user_id: user._id, name: user.name, email: user.email, password: '', role_id: user.role_id });
            setUserLoading(false);
        } else {
            setUserLoading(false);
        }
    }, [user]);


    /**
     * It takes the name and value of the input, validates it, and sets the errors and data state
     * @param {Object} e form object
     * @param {String} e.name input name
     * @param {String} e.value input value
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        /* Validating the input and setting the errors state. */
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * The function handles the submit event of the form. It prevents the default action of the form,
     * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
     * dispatches the corresponding action
     * @param {Object} e the event object
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        /* Iterating through the data object and validating each key value pair. */
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }

        /* Checking if the form is valid or not. If the form is not valid, it sets the errors state and returns. */
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchUpdateUserAction(data);
    }

    /**
     * This function dispatches an action to update or add a user and redirects to the users page upon
     * success.
     * @param formData - The `formData` parameter is an object that contains the data to be updated or
     * added for a user. It is passed as an argument to the `dispatchUpdateUserAction` function.
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    async function dispatchUpdateUserAction(formData) {
        const isCreated = user && user._id ? await updateUser(formData) : await addUser(formData);
        if (isCreated && isCreated.status === 1) {
            let redirectPath = `${postAuthRoutes('users').path}`;
            navigate(redirectPath);
        }
    }

    /**
     * The function cancels the user and redirects to the users page.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    function cancelUser() {
        let redirectPath = `${postAuthRoutes('users').path}`;
        navigate(redirectPath);
    }

    return (
        <Animate>
            <Grid container spacing={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                <Grid item xs={4}>
                    <Form>
                        <FormHeader title={user && user._id ? postAuthRoutes('update_user').name : postAuthRoutes('create_user').name} />
                        <form onSubmit={handleSubmit} noValidate>
                            <FormContent >
                                <Grid container spacing={2} direction="row" display={`flex`} justifyContent="center" alignItems="center">

                                    <Grid item xs={12}>
                                        <FormInput
                                            tabIndex={1}
                                            label='Name'
                                            name='name'
                                            onChange={handleChange}
                                            value={data.name}
                                            error={(action.isSubmitted && (errors.name)) ? (errors.name) : ''}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormInput
                                            tabIndex={3}
                                            label='Email'
                                            name='email'
                                            onChange={handleChange}
                                            value={data.email}
                                            disabled={data.user_id}
                                            error={(action.isSubmitted && (errors.email)) ? (errors.email) : ''}
                                        />
                                    </Grid>
                                    {!isSetUserLoading && !roles_select_list_loading &&
                                        <Grid item xs={12}>
                                            <FormSelect
                                                tabIndex={5}
                                                label='Role'
                                                placeholder="Select role"
                                                name='role_id'
                                                onChange={handleChange}
                                                value={data.role_id}
                                                error={(action.isSubmitted && (errors.role_id)) ? (errors.role_id) : ''}
                                                data={roles_select_list}
                                            />
                                        </Grid>
                                    }
                                    {!data.user_id && <Grid item xs={12} sm={12}>
                                        <FormPassword
                                            tabIndex={4}
                                            label='Password'
                                            name='password'
                                            onChange={handleChange}
                                            value={data.password}
                                            error={(action.isSubmitted && (errors.password)) ? (errors.password) : ''}
                                        />
                                    </Grid>
                                    }
                                </Grid>
                            </FormContent>
                            <FormAction>
                                <CancelButton onClick={cancelUser} tabIndex={7} />
                                <SubmitButton label={user && user._id ? 'Update' : 'Create'} loading={create_update_user_loading} tabIndex={4} />
                            </FormAction>
                        </form>
                    </Form>
                </Grid>
            </Grid>
        </Animate>
    );
}

export { CreateUpdateUser };;;