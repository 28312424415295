import globals from "../../_root/base/globals";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...globals,
  // "a, a:link, a:visited": {
  //   color: `${link.main} `,
  // },
  // "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
  //   color: `${link.main} `,
  // },
  // "a.link:hover, .link:hover, a.link:focus, .link:focus": {
  //   color: `${info.main} `,
  // },
};
