import React from 'react';
import { Grid, styled } from '@mui/material';

const NoDataStyled = styled(({ ...props }) => (<Grid  {...props} />))`
		min-height: 85vh;
		background-color:  ${props => props.theme.palette.background.card};
		& img {
			width: 300px;
			margin-bottom: 10px;
		}
		& .MuiTypography-root {
			color:  ${props => props.theme.palette.text.primary};
		}
		& .MuiTypography-h1 {
			color:  ${props => props.theme.palette.text.primary};
			& .error {
				color:  ${props => props.theme.palette.error.main};
			}
        }
`;

export { NoDataStyled };