import React, { useEffect, useState } from 'react'
import { Autocomplete, FormHelperText, TextField } from '@mui/material';
import { FormSelectStyled } from '.';

function FormSelect({ label, data, name, value, placeholder, className, onChange, tabIndex, error, info, ...props }) {

    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (value) {
            const selectedObj = data.find(obj => { return obj.value === value })
            setSelected(selectedObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const handleSelectChange = (e, selectedObj) => {
        if (selectedObj) {
            let target = { 'name': name, 'value': selectedObj.value, 'type': 'select' }
            setSelected(selectedObj);
            onChange({ target })
        } else {
            setSelected(null);
            onChange({ target: { 'name': name, 'value': '', 'type': 'select' } })
        }
    }

    return (
        <FormSelectStyled fullWidth error >
            <Autocomplete
                tabIndex={tabIndex}
                options={data}
                onChange={handleSelectChange}
                value={selected}
                name={name}
                renderInput={(params) => <TextField {...params} label={label} error={error ? true : false} fullWidth />}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => ((value !== undefined) && (option.value === value.value))}
                {...props}
            />
            {(info) && <FormHelperText id={`helper_${name}`}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error>{error}</FormHelperText>}
        </FormSelectStyled>
    )
}

export { FormSelect }
