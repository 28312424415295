module.exports = {
    /**
     * function to validate the forgot password api
     * @param {String} name Input name
     * @param {String} value Input value
     * @param {Object} [data={}] All input values stored in the state
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const errors = {};
        switch (name) {
            case 'password': {
                const confirmPassword = data.confirm_password ?? '';
                errors.password = value.length < 6 ? 'Password must be 6 characters long.' : '';
                if (confirmPassword !== '' && confirmPassword === value)
                    errors.confirm_password = '';
                if (value === '')
                    errors.password = 'Enter password.';
                break;
            } case 'confirm_password': {
                const password = data.password ?? '';
                errors.confirm_password = value.length < 6 ? 'Confirm password must be 6 characters long.' : '';
                if (password !== '' && password !== value)
                    errors.confirm_password = 'Confirm password should match with the password.';
                if (value === '')
                    errors.confirm_password = 'Re-enter your password.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}