
import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from "react-router-dom";
import { cookieServices } from '../../_helpers';
import { preAuthRoutes } from '../../routes';
import { Box, Container } from '@mui/material';
import { Header, MenuBar, PostAuthStyled } from '.';
const PostAuth = () => {

    /** Initialize plugins and variables */
    let navigate = useNavigate();

    /* Destructuring the state from redux store. */
    const { isDrawerOpen: open, themeLayout } = useSelector((state) => state.GlobalReducer);

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        /* This code is checking if there is an access token stored in the browser's cookies. If there is
        no access token, it will navigate the user to the sign-in page using the `useNavigate` hook
        from the `react-router-dom` library. The `preAuthRoutes` function is used to get the path of
        the sign-in page from the routes configuration. */
        if (!cookieServices.get('accessToken'))
            navigate(`/${preAuthRoutes('sign_in').path}`);

    }, [navigate]);


    return (
        <PostAuthStyled>
            <Fragment>
                <Header />
                <MenuBar />

                {themeLayout === 'vertical' ? (
                    <Box sx={{ padding: `40px`, paddingLeft: (open ? `300px` : `108px`) }} className={`${open ? 'drawer-open' : ``}`} > <Outlet /> </Box>
                ) : (
                    <Container component="main" >  <Outlet />    </Container>
                )}
            </Fragment>
        </PostAuthStyled>
    );
};

export { PostAuth };
