import { failure, request, success } from ".";
import { ProfileServices } from "../services";
import { GlobalAction } from "./GlobalAction";

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

export const USER_PERMISSION_REQUEST = 'USER_PERMISSION_REQUEST';
export const USER_PERMISSION_SUCCESS = 'USER_PERMISSION_SUCCESS';
export const USER_PERMISSION_FAILURE = 'USER_PERMISSION_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const GET_ACTIVITY_LOG_REQUEST = 'GET_ACTIVITY_LOG_REQUEST';
export const GET_ACTIVITY_LOG_SUCCESS = 'GET_ACTIVITY_LOG_SUCCESS';
export const GET_ACTIVITY_LOG_FAILURE = 'GET_ACTIVITY_LOG_FAILURE';

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const MARK_ALL_NOTIFICATIONS_READ_REQUEST = 'MARK_ALL_NOTIFICATIONS_READ_REQUEST';
export const MARK_ALL_NOTIFICATIONS_READ_SUCCESS = 'MARK_ALL_NOTIFICATIONS_READ_SUCCESS';
export const MARK_ALL_NOTIFICATIONS_READ_FAILURE = 'MARK_ALL_NOTIFICATIONS_READ_FAILURE';

export const MARK_NOTIFICATION_READ_REQUEST = 'MARK_NOTIFICATION_READ_REQUEST';
export const MARK_NOTIFICATION_READ_SUCCESS = 'MARK_NOTIFICATION_READ_SUCCESS';
export const MARK_NOTIFICATION_READ_FAILURE = 'MARK_NOTIFICATION_READ_FAILURE';


export const ProfileAction = {

    /**
     * The above code is defining a function called `getUserDetails` which returns a function that
     * takes a `dispatch` argument. When `getUserDetails` is called, it dispatches a `request` action
     * and then calls a service function called `ProfileServices.getUserDetails()`. If the service call
     * is successful, it dispatches a `success` action with the response data. If the service call
     * fails, it dispatches a `failure` action with the error data. The `request`, `success`, and
     * `failure` functions are all defined within the `getUserDetails` function.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    getUserDetails: (params = {}) => {

        return dispatch => {
            dispatch(request(USER_REQUEST, params));
            dispatch(GlobalAction.initialLoader(true));
            return ProfileServices.getUserDetails(params)
                .then(
                    response => {
                        dispatch(GlobalAction.setTheme(response.data.is_dark_mode));
                        dispatch(GlobalAction.setThemeLayout(response.data.layout));
                        dispatch(GlobalAction.initialLoader(false));
                        return dispatch(success(USER_SUCCESS, params, response));
                    },
                    error => { return dispatch(failure(USER_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `getUserPermission` which returns a function that
     * takes a `dispatch` argument. When `getUserPermission` is called, it dispatches a `request`
     * action and then calls a service function called `ProfileServices.getUserPermission()`. If the
     * service call is successful, it dispatches a `success` action with the response data, message,
     * status, and status code. If the service call fails, it dispatches a `failure` action with the
     * error data, errors, message, status, and status code.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    getUserPermission: (params = {}) => {

        return dispatch => {
            dispatch(request(USER_PERMISSION_REQUEST, params));
            return ProfileServices.getUserPermission(params)
                .then(
                    response => { return dispatch(success(USER_PERMISSION_SUCCESS, params, response)); },
                    error => { return dispatch(failure(USER_PERMISSION_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining an action creator function called `updateTheme` that returns a
     * function that takes a `dispatch` argument. When this function is called, it dispatches a
     * `USER_UPDATE_REQUEST` action and then calls the `ProfileServices.updateTheme()` function. If the
     * call to `ProfileServices.updateTheme()` is successful, it dispatches a `USER_UPDATE_SUCCESS`
     * action with the response data. If the call to `ProfileServices.updateTheme()` fails, it
     * dispatches a `USER_UPDATE_FAILURE` action with the error data.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    updateTheme: (params = {}) => {

        return dispatch => {
            dispatch(request(USER_UPDATE_REQUEST, params));
            return ProfileServices.updateTheme(params)
                .then(
                    response => {
                        dispatch(GlobalAction.setTheme(response.data.is_dark_mode));
                        dispatch(GlobalAction.setThemeLayout(response.data.layout));
                        return dispatch(success(USER_UPDATE_SUCCESS, params, response));
                    },
                    error => { return dispatch(failure(USER_UPDATE_FAILURE, params, error)); }
                );
        };

    },



    updateLayout: (params = {}) => {

        return dispatch => {
            dispatch(request(USER_UPDATE_REQUEST, params));
            return ProfileServices.updateLayout(params)
                .then(
                    response => {
                        dispatch(GlobalAction.setTheme(response.data.is_dark_mode));
                        dispatch(GlobalAction.setThemeLayout(response.data.layout));
                        return dispatch(success(USER_UPDATE_SUCCESS, params, response));
                    },
                    error => { return dispatch(failure(USER_UPDATE_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `updateUserDetails` which takes in a parameter
     * called `params`. This function returns another function that takes in a parameter called
     * `dispatch`.
     * @param {Object} params - The params which are used fr the api.
     * @param {String} params.name - Name of the user
     * @param {String} params.email - Email of the user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    updateUserDetails: (params) => {

        return dispatch => {
            dispatch(request(USER_UPDATE_REQUEST, params));
            return ProfileServices.updateUserDetails(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(USER_UPDATE_SUCCESS, params, response)); },
                    error => { return dispatch(failure(USER_UPDATE_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `changePassword` that returns a function that takes
     * a `dispatch` parameter. This function dispatches three actions: `CHANGE_PASSWORD_REQUEST`,
     * `CHANGE_PASSWORD_SUCCESS`, and `CHANGE_PASSWORD_FAILURE`. It also calls a service function
     * called `ProfileServices.changePassword` with the `params` parameter. If the service call is
     * successful, it dispatches the `CHANGE_PASSWORD_SUCCESS` action with the response data. If the
     * service call fails, it dispatches the `CHANGE_PASSWORD_FAILURE` action with the error data.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    changePassword: (params) => {

        return dispatch => {
            dispatch(request(CHANGE_PASSWORD_REQUEST, params));
            return ProfileServices.changePassword(params)
                .then(
                    response => { return dispatch(success(CHANGE_PASSWORD_SUCCESS, params, response)); },
                    error => { return dispatch(failure(CHANGE_PASSWORD_FAILURE, params, error)); }
                );
        };

    },
    /**
     * The above code is defining a function called `getActivityList` that returns a function that
     * takes a `dispatch` parameter. This function dispatches three different actions based on the
     * result of a call to `ProfileServices.getActivityList(params)`. The actions dispatched are
     * `GET_ACTIVITY_LOG_REQUEST`, `GET_ACTIVITY_LOG_SUCCESS`, and `GET_ACTIVITY_LOG_FAILURE`. These
     * actions are dispatched with different parameters based on the result of the call to
     * `ProfileServices.getActivityList(params)`.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getActivityList: (params) => {

        return dispatch => {
            dispatch(request(GET_ACTIVITY_LOG_REQUEST, params));
            return ProfileServices.getActivityList(params)
                .then(
                    response => { return dispatch(success(GET_ACTIVITY_LOG_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_ACTIVITY_LOG_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `getNotifications` which returns a function that
     * takes a `dispatch` parameter. This function dispatches three actions based on the result of a
     * call to `ProfileServices.getNotifications(params)`. The three actions are `request`,
     * `success`, and `failure`. The `request` action is dispatched first, followed by either the
     * `success` or `failure` action depending on whether the call to
     * `ProfileServices.getNotifications(params)` was successful or not. The `success` action is
     * dispatched with the response data if the call was successful, and
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getNotifications: (params) => {

        return dispatch => {
            dispatch(request(GET_NOTIFICATIONS_REQUEST, params));
            return ProfileServices.getNotifications(params)
                .then(
                    response => { return dispatch(success(GET_NOTIFICATIONS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_NOTIFICATIONS_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `markAllRead` which returns a function that takes a
     * `dispatch` argument. When this function is called, it dispatches a `request` action, then calls
     * a service function called `ProfileServices.markAllRead()` which returns a promise. If the
     * promise is resolved successfully, it dispatches a `success` action with the response message and
     * data, and if the promise is rejected, it dispatches a `failure` action with the error data and
     * message. The `request`, `success`, and `failure` functions are defined within the `mark
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    markAllRead: (params = {}) => {

        return dispatch => {
            dispatch(request(MARK_ALL_NOTIFICATIONS_READ_REQUEST, params));
            return ProfileServices.markAllRead(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(MARK_ALL_NOTIFICATIONS_READ_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MARK_ALL_NOTIFICATIONS_READ_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `markRead` that returns a function that takes a
     * `dispatch` parameter. The returned function dispatches an action with a `request` parameter and
     * then calls a service function called `ProfileServices.markRead` with the `params` parameter. If
     * the service call is successful, it dispatches a success action with the `params` and `response`
     * parameters. If the service call fails, it dispatches a failure action with the `params` and
     * `error` parameters. The `request`, `success`, and `failure` functions are helper functions that
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    markRead: (params) => {

        return dispatch => {
            dispatch(request(MARK_NOTIFICATION_READ_REQUEST, params));
            return ProfileServices.markRead(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(MARK_NOTIFICATION_READ_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MARK_NOTIFICATION_READ_FAILURE, params, error)); }
                );
        };

    },

    /**
     * The above code is defining a function called `deleteAccount` which returns a function that takes
     * a `dispatch` parameter. When the function is called, it dispatches an action to show a page
     * loader, then calls a service function called `ProfileServices.deleteAccount()` to delete the
     * user's account. If the deletion is successful, it dispatches an action to hide the page loader
     * and returns the response. If there is an error, it returns the error. This code is likely part
     * of a larger Redux action creator file.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    deleteAccount: () => {
        return dispatch => {
            dispatch(GlobalAction.showPageLoader());
            return ProfileServices.deleteAccount()
                .then(
                    response => { dispatch(GlobalAction.pageLoader(false)); return response; },
                    error => { return error; }
                );
        };

    }


};
