import React from 'react'
import { Box, styled } from '@mui/material'

const InitialLoaderStyled = styled(({ ...props }) => <Box {...props} />)`
	background: #000000;
	overflow: hidden;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	z-index: 99999;
	& .initial-loader-box {
		bottom: 0;
		height: ${(props) => `${props.theme.functions.pxToRem(75)}`};
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		& img {
			height: ${(props) => `${props.theme.functions.pxToRem(75)}`};
		}
	}
`

export { InitialLoaderStyled }
