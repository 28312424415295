import { Box, Grid, Stack } from '@mui/material';
import React, { Fragment } from 'react';
import { postAuthRoutes } from '../../../../routes';
import { ListUsers } from './list-users';
import { useSelector } from 'react-redux';
import { AbilityCan } from '../../../../_helpers/permission/AbilityCan';
import { PageHeader, GeneralButton, RouteLink } from '../../../../_components';

function Users() {

    /* Destructuring the state from redux store. */
    const { user } = useSelector((state) => state.ProfileReducer);

    return (
        <Fragment>
            <Box>
                <PageHeader
                    title={postAuthRoutes('users').name}
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`}>
                            <AbilityCan I='admin_create/update_user' passThrough={(user.role === 'developer') ? true : false}>
                                <RouteLink to={postAuthRoutes('create_user').path} label={(<GeneralButton label={postAuthRoutes('create_user').name} />)} />
                            </AbilityCan>
                        </Stack>
                    }
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <ListUsers />
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    );
}

export { Users };