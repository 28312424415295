/**
 * The function validates the input fields for changing password and returns any errors.
 * @param {String} name - The name of the input field being validated (e.g. "current_password", "new_password",
 * "confirm_password").
 * @param {String} value - The value of the input field being validated.
 * @param {Object} data - The `data` parameter is an object that contains the current values of the form fields.
 * It is used to compare the values of the `new_password` and `confirm_password` fields to ensure they
 * match.
 * @returns The function `validateChangePassword` returns an object `errors` which contains error
 * messages for the input fields `current_password`, `new_password`, and `confirm_password`. The error
 * messages are empty strings if there are no errors, and contain specific error messages if there are
 * errors in the input values.
 * @author Akshay N
 * @created_at 21/05/2023
 */
const validateChangePassword = (name, value, data) => {
    const errors = {};
    switch (name) {
        case 'current_password': {
            errors.current_password = '';
            if (value === '')
                errors.current_password = 'Enter current password';
            break;
        } case 'new_password': {
            const confirmPassword = data.confirm_password ?? '';
            errors.new_password = value.length < 6 ? 'Password must be 6 characters long' : '';
            if (confirmPassword !== '' && confirmPassword === value)
                errors.confirm_password = '';
            if (value === '')
                errors.new_password = 'Enter new password';
            break;
        } case 'confirm_password': {
            const password = data.new_password ?? '';
            errors.confirm_password = value.length < 6 ? 'Confirm password must be 6 characters long' : '';
            if (password !== '' && password !== value)
                errors.confirm_password = 'Confirm password should match with the password';
            if (value === '')
                errors.confirm_password = 'Enter confirm password';
            break;
        } default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}
export { validateChangePassword };