
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {},
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  variants: []
};
