import link from "../../../_root/components/link/link";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...link,
  styleOverrides: {
    ...link.styleOverrides,
    root: {
      ...link.styleOverrides.root,
      color: `${colors.link.main}`,
    },
  },
};
