import React from 'react';
import { Stack } from '@mui/material';
import { FormActionStyled } from '.';

const FormAction = ({ ...props }) => {
    return (
        <FormActionStyled>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} {...props} >
                {props.children}
            </Stack>
        </FormActionStyled>
    );
};

export { FormAction };