import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { handleInputChange, validateForm } from '../../../_helpers';
import { preAuthRoutes } from '../../../routes';

import { FormInput, FormPassword, SubmitButton, RouteLink } from '../../../_components';
import { Grid, Typography } from '@mui/material';
import { PreAuth } from '../../../_layouts/pre-auth';

import { AuthAction } from '../../../redux/actions';
import { validate } from '.';
import { ArrowForward, ArrowRightAlt, Email, Lock, Person } from '@mui/icons-material';

// Initialize form input values to null
const inputs = { name: '', email: '', password: '', confirm_password: '' };

function Register() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    /* Destructuring the state from redux store. */
    const { register_loading: registrationInProgress, register_errors: registrationErrors } = useSelector((state) => state.AuthReducer);
    const register = (params) => dispatch(AuthAction.register(params));

    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        setErrors({ ...registrationErrors });
    }, [registrationErrors]);

    /**
      * It takes the name and value of the input, validates it, and sets the errors and data state
      * @param {Object} e form object
      * @param {String} e.name input name
      * @param {String} e.value input value
      * @return {null}
      * @author Akshay N
      * @created_at 20/05/2023
      */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        /* Validating the input and setting the errors state. */
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
      * The function handles the submit event of the form. It prevents the default action of the form,
      * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
      * dispatches the corresponding action
      * @param {Object} e the event object
      * @return {null}
      * @author Akshay N
      * @created_at 20/05/2023
      */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        /* Iterating through the data object and validating each key value pair. */
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }

        /* Checking if the form is valid or not. If the form is not valid, it sets the errors state and returns. */
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchRegisterAction();
    }

    /**
     * function to dispatch registration api
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    async function dispatchRegisterAction() {
        const registered = await register(data);
        if (registered && registered.status === 1) {
            /**
            * Checking if the user is redirected from a page, and
            * if so, redirects to that page, else redirects to the sign in page.
            */
            let redirectPath = ((location.state && location.state.from.pathname) ? (location.state.from.pathname) : preAuthRoutes('sign_in').path);
            navigate(`${redirectPath}`);
        }
    }


    return (
        <PreAuth>
            <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <Typography variant="h5" >{`${preAuthRoutes('register').name}`}</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <FormInput
                            tabIndex={1}
                            label='Name'
                            name='name'
                            value={data.name}
                            error={action.isSubmitted && errors.name ? errors.name : ''}
                            onChange={handleChange}
                            startIcon={<Person />}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <FormInput
                            tabIndex={3}
                            label='Email'
                            name='email'
                            value={data.email}
                            error={action.isSubmitted && errors.email ? errors.email : ''}
                            onChange={handleChange}
                            startIcon={<Email />}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <FormPassword
                            tabIndex={4}
                            label='Password'
                            name='password'
                            value={data.password}
                            error={action.isSubmitted && errors.password ? errors.password : ''}
                            onChange={handleChange}
                            startIcon={<Lock />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPassword
                            tabIndex={5}
                            label='Re-enter password'
                            name='confirm_password'
                            value={data.confirm_password}
                            error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                            onChange={handleChange}
                            startIcon={<Lock />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" component={`p`}>Use 6 or more characters with a mix of letters, numbers and symbols</Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between" >
                        <SubmitButton tabIndex={8} label={'Create'} loading={registrationInProgress} endIcon={<ArrowForward />} />
                    </Grid>
                    <Grid item xs={12} >
                        <RouteLink to={preAuthRoutes('sign_in').path} label={<Fragment>{preAuthRoutes('sign_in').name} instead <ArrowRightAlt /></Fragment>} />
                    </Grid>
                </Grid>
            </form>
        </PreAuth>
    );
}

export { Register };
